import React, { useState } from "react";
import { useEffect } from "react";

const FilterTab = ({
  title,
  filterOptions,
  setSelectedFilters,
  selectedFilters,
}) => {
  const handleCheckboxChange = (e, filterId) => {
    const isChecked = e.target.checked;
    setSelectedFilters((prevFilters) => {
      if (isChecked) {
        return [...prevFilters, filterId];
      } else {
        return prevFilters.filter((id) => id !== filterId);
      }
    });
  };

  return (
    <div className="sidebar-body">
      <ul
        className="checkbox-container categories-list"
        style={{ padding: 0, listStyle: "none" }}
      >
        {filterOptions?.map((option, index) => (
          <li key={option?._id}>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                name={title}
                value={option?._id}
                onChange={(e) => handleCheckboxChange(e, option?._id)}
                className="custom-control-input"
                checked={selectedFilters.includes(option?._id)}
                id={`modalCustomCheck-${title.split(" ")[0]}-${index}`}
              />
              <label
                className="custom-control-label"
                htmlFor={`modalCustomCheck-${title.split(" ")[0]}-${index}`}
              >
                {option.name}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FilterTabs = ({
  filters,
  setSelectedFilters,
  priceRange,
  setPriceFilter,
  selectedFilters,
  priceFilter,
  fetchPage,
}) => {
  const [activeTab, setActiveTab] = useState("price");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    setMin(priceFilter[0]);
    setMax(priceFilter[1]);
  }, [priceFilter]);

  const handleFilter = () => {
    setPriceFilter([min, +max]);
    fetchPage();
  };
  const handleClear = () => {
    setPriceFilter([priceRange.min, priceRange.max]);
    fetchPage();
  };

  return (
    <>
      <div className="modal-body">
        <div className="product-details-reviews pt-2">
          <div className="row">
            <div className="col-lg-12">
              <div className="product-review-info">
                <ul
                  className="tabs dreaming-tabs "
                  style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
                >
                  <li
                    className={`description_tab p-0 ${
                      activeTab === "price" ? "active" : ""
                    }`}
                  >
                    <a
                      className={activeTab === "price" ? `active` : ""}
                      style={{ margin: 0 }}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("price");
                      }}
                    >
                      Price
                    </a>
                  </li>
                  {filters?.map((filter, ind) => (
                    <li
                      key={filter._id}
                      className={`additional_information_tab p-0 ${
                        activeTab === filter._id ? "active" : ""
                      }`}
                    >
                      <a
                        className={activeTab === filter._id ? `active` : ""}
                        style={{ margin: 0 }}
                        data-bs-toggle="tab"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab(filter._id);
                        }}
                      >
                        {filter?.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="tab-content reviews-tab">
                  {filters?.map((filter) => (
                    <div
                      key={filter._id}
                      className={`tab-pane fade ${
                        activeTab === filter._id ? "show active" : ""
                      }`}
                    >
                      <div className="tab-content">
                        <FilterTab
                          title={filter?.name}
                          filterOptions={filter.filterOptions}
                          setSelectedFilters={setSelectedFilters}
                          selectedFilters={selectedFilters}
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    className={`tab-pane fade ${
                      activeTab === "price" ? "show active" : ""
                    }`}
                  >
                    <div className="tab-content">
                      <div className="sidebar-body">
                        {/* <div className="price-range-wrap">
                          <div ref={sliderRef} className="price-range"></div>
                          <div className="range-slider"> */}
                        <form className="d-flex flex-column gap-2 ">
                          <div className="d-flex gap-4 flex-wrap">
                            <div
                              className="custom-control "
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="modalAmountMin">Min: </label>
                              <input
                                type="text"
                                id="modalAmountMin"
                                value={min}
                                onChange={(e) => {
                                  setMin(e.target.value);
                                }}
                                className="form-control mb-2"
                                style={{
                                  width: "70px",
                                  background: "#ebebeb",
                                  border: "none",
                                  borderRadius: "10px",
                                  paddingLeft: "5px",
                                }}
                              />
                            </div>
                            <div
                              className="custom-control"
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <label htmlFor="modalAmountMax">Max: </label>
                              <input
                                type="text"
                                id="modalAmountMax"
                                value={max}
                                onChange={(e) => {
                                  setMax(e.target.value);
                                }}
                                className="form-control mb-2"
                                style={{
                                  maxWidth: "70px",
                                  background: "#ebebeb",
                                  border: "none",
                                  borderRadius: "10px",
                                  paddingLeft: "5px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ paddingTop: "10px" }}
                          >
                            <button
                              type="button"
                              onClick={handleClear}
                              className="filter-btn themeBtn"
                            >
                              Clear
                            </button>
                            <button
                              type="button"
                              className="filter-btn themeBtn"
                              onClick={handleFilter}
                            >
                              Apply
                            </button>
                          </div>
                        </form>
                        {/* </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterTabs;
