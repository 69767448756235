import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MyAccountLayout from "../../layouts/MyAccount";
import { Link } from "react-router-dom";

const Currencies = () => {
  const { token } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState({});
  const [loadingStatus, setLoadingStatus] = useState(false);

  const closeModal = () => {
    setCurrency(null);
    setShowModal(false);
  };

  const fetchAllCurrencies = () => {
    setLoadingStatus(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/currencies`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setCurrencies(response.data);
        setLoadingStatus(false);
      })
      .catch((error) => {
        toast.error("Failed to fetch currencies");
        setLoadingStatus(false);
      });
  };

  const showDetails = (currencyId) => {
    setCurrency(currencies.find((c) => c._id === currencyId));
    setShowModal(true);
  };

  const deleteCurrency = async (currencyId) => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/currencies/${currencyId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Currency...",
          success: "Currency Deleted Successfully!",
          error: "Failed to Delete Currency.",
        }
      );

      fetchAllCurrencies();
    } catch (error) {
      toast.error("Error deleting currency");
    }
  };

  useEffect(() => {
    fetchAllCurrencies();
  }, []);

  return (
    <>
      <CurrencyDetailModal
        showModal={showModal}
        closeModal={closeModal}
        currency={currency}
        setCurrency={setCurrency}
        setCurrencies={setCurrencies}
      />
      <NewCurrencyModal
        showModal={addNewModal}
        closeModal={() => {
          setAddNewModal(false);
        }}
        fetchAllCurrencies={fetchAllCurrencies}
      />
      <MyAccountLayout>
        <div className="kaycee-MyAccount-content">
          <div className="d-flex align-items-center justify-content-end mb-4 ">
            <button
              className="button alt themeBtn "
              onClick={() => {
                setAddNewModal(true);
              }}
            >
              Add Currency
            </button>
          </div>
          <div className="myaccount-table table-responsive text-center">
            {loadingStatus && (
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-4"
                style={{ height: "40vh" }}
              >
                <img src="/assets/images/Rolling-1s-200px.svg" />
              </div>
            )}
            {!loadingStatus &&
              (currencies?.length <= 0 ? (
                <div className="kaycee-Message kaycee-Message--info kaycee-info">
                  No Currencies available yet.
                </div>
              ) : (
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Symbol</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currencies?.map((currency, index) => {
                      return (
                        <tr key={currency?._id}>
                          <td>{index + 1}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {currency?.name}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {currency?.code}
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {currency?.symbol}
                          </td>
                          <td
                            style={{ textTransform: "capitalize" }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <Link
                              onClick={() => {
                                showDetails(currency?._id);
                                console.log(currency?._id);
                              }}
                              className="btn themeBtn"
                              style={{ marginRight: "10px" }}
                            >
                              Edit
                            </Link>
                            <Link
                              onClick={() => {
                                deleteCurrency(currency?._id);
                              }}
                              className="btn themeBtn"
                            >
                              Delete
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ))}
          </div>
        </div>
      </MyAccountLayout>
    </>
  );
};

const CurrencyDetailModal = ({
  showModal,
  closeModal,
  currency,
  setCurrencies,
}) => {
  const { token } = useSelector((state) => state.user);

  const [updatedCurrencyDetails, setUpdatedCurrencyDetails] =
    useState(currency);
  useEffect(() => {
    setUpdatedCurrencyDetails(currency);
  }, [currency]);

  const updateCurrency = async () => {
    try {
      const response = await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/currencies/${currency._id}`,
          updatedCurrencyDetails,
          { headers: { Authorization: token } }
        ),
        {
          pending: "Updating Currency Details...",
          success: "Currency Details Updated Successfully!",
          error: "Failed to Update Currency Details.",
        }
      );

      setCurrencies((currCurrencies) =>
        currCurrencies.map((curr) =>
          curr._id === currency?._id ? response.data : curr
        )
      );
    } catch (error) {
      toast.error("Error updating currency");
    }
  };

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="mt-3 ms-2">{updatedCurrencyDetails?.name}</h5>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <table className="table ">
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>Name</td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        value={updatedCurrencyDetails?.name || ""}
                        onChange={(e) =>
                          setUpdatedCurrencyDetails((curr) => {
                            return { ...curr, name: e.target.value };
                          })
                        }
                        className="form-control mb-2"
                        placeholder="Name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Code</td>
                    <td>
                      <input
                        type="text"
                        name="code"
                        value={updatedCurrencyDetails?.code || ""}
                        onChange={(e) =>
                          setUpdatedCurrencyDetails((curr) => {
                            return { ...curr, code: e.target.value };
                          })
                        }
                        className="form-control mb-2"
                        placeholder="Code"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Symbol</td>
                    <td>
                      <input
                        type="text"
                        name="symbol"
                        value={updatedCurrencyDetails?.symbol || ""}
                        onChange={(e) =>
                          setUpdatedCurrencyDetails((curr) => {
                            return { ...curr, symbol: e.target.value };
                          })
                        }
                        className="form-control mb-2"
                        placeholder="Symbol"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="modal-footer"
              style={{ textTransform: "capitalize" }}
            >
              <button
                className="btn themeBtn"
                onClick={updateCurrency}
                disabled={
                  updatedCurrencyDetails?.name === currency?.name &&
                  updatedCurrencyDetails?.code === currency?.code &&
                  updatedCurrencyDetails?.symbol === currency?.symbol
                }
              >
                Update Currency
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewCurrencyModal = ({ showModal, closeModal, fetchAllCurrencies }) => {
  const { token } = useSelector((state) => state.user);
  const [newCurrencyDetails, setNewCurrencyDetails] = useState({
    name: "",
    code: "",
    symbol: "",
  });

  const addCurrency = async () => {
    try {
      await toast.promise(
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/currencies`,
          newCurrencyDetails,
          {
            headers: { Authorization: token },
          }
        ),
        {
          pending: "Adding Currency...",
          success: "Currency Added Successfully!",
          error: "Failed to Add Currency.",
        }
      );

      fetchAllCurrencies();
      closeModal();
    } catch (error) {
      toast.error("Failed to add Currency");
    }
  };

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="mt-3 ms-2">New Currency</h5>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <table className="table ">
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>Name</td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        value={newCurrencyDetails.name || ""}
                        onChange={(e) =>
                          setNewCurrencyDetails((curr) => {
                            return { ...curr, name: e.target.value };
                          })
                        }
                        className="form-control mb-2"
                        placeholder="Name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Code</td>
                    <td>
                      <input
                        type="text"
                        name="code"
                        value={newCurrencyDetails.code || ""}
                        onChange={(e) =>
                          setNewCurrencyDetails((curr) => {
                            return { ...curr, code: e.target.value };
                          })
                        }
                        className="form-control mb-2"
                        placeholder="Code"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Symbol</td>
                    <td>
                      <input
                        type="text"
                        name="symbol"
                        value={newCurrencyDetails.symbol || ""}
                        onChange={(e) =>
                          setNewCurrencyDetails((curr) => {
                            return { ...curr, symbol: e.target.value };
                          })
                        }
                        className="form-control mb-2"
                        placeholder="Symbol"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="modal-footer"
              style={{ textTransform: "capitalize" }}
            >
              <button
                className="btn themeBtn"
                onClick={addCurrency}
                disabled={
                  !newCurrencyDetails.name ||
                  !newCurrencyDetails.code ||
                  !newCurrencyDetails.symbol
                }
              >
                Add Currency
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Currencies;
