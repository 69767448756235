import React, { useEffect, useState } from "react";
import MyAccountLayout from "../../layouts/MyAccount";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const options = ["today", "weekly", "monthly", "yearly"];
  const [activeIndex, setActiveIndex] = useState(0);
  const [orderReportData, setOrderReportData] = useState({});
  const [summaryReportData, setSummaryReportData] = useState({});
  const { token } = useSelector((state) => state.user);
  const [loadingSummaryReport, setLoadingSummaryReport] = useState(false);
  const [loadingOrderReport, setLoadingOrderReport] = useState(false);

  const handleArrowClick = (direction) => {
    let newIndex = activeIndex;
    if (direction === "up") {
      newIndex = (activeIndex - 1 + options.length) % options.length;
    } else if (direction === "down") {
      newIndex = (activeIndex + 1) % options.length;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    setLoadingOrderReport(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/order/report/${options[activeIndex]}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        setOrderReportData(response.data);
        setLoadingOrderReport(false);
      })
      .catch(() => {
        toast.error("Failed to fetch order report");
        setLoadingOrderReport(false);
      });
  }, [activeIndex]);

  useEffect(() => {
    setLoadingSummaryReport(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/report`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setSummaryReportData(response.data);
        setLoadingSummaryReport(false);
      })
      .catch(() => {
        toast.error("Failed to fetch summary report");
        setLoadingSummaryReport(false);
      });
  }, []);

  return (
    <MyAccountLayout>
      <div className="kaycee-MyAccount-content">
        <div className="col-12 p-0 d-card">
          <h3 className="d-card-heading mt-0">Summary</h3>
          {loadingSummaryReport ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-4">
              <img
                src="/assets/images/Rolling-1s-200px.svg"
                style={{ height: "125px" }}
              />
            </div>
          ) : (
            <div className="p-4">
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Total Orders</h4>
                <p>{summaryReportData.order || 0}</p>
              </div>
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Total Products</h4>
                <p>{summaryReportData.product || 0}</p>
              </div>
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Total Filter</h4>
                <p>{summaryReportData.filter || 0}</p>
              </div>
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Total Users</h4>
                <p>{summaryReportData.user || 0}</p>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 p-0 d-card">
          <h3 className="d-card-heading mt-0 d-flex align-items-center justify-content-between">
            Order Status
            <div
              className="mode-switcher"
              style={{ fontSize: ".9rem", textTransform: "capitalize" }}
            >
              <div
                className="selected-option "
                onClick={() => handleArrowClick("up")}
                style={{
                  cursor: "pointer",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                }}
              >
                {options[activeIndex]}
              </div>
              <div
                className="arrows "
                style={{ gap: "10px", marginLeft: "5px" }}
              >
                <span
                  className="arrow up"
                  onClick={() => handleArrowClick("up")}
                ></span>
                <span
                  className="arrow down"
                  onClick={() => handleArrowClick("down")}
                ></span>
              </div>
            </div>
          </h3>
          {loadingOrderReport ? (
            <div className="d-flex flex-column justify-content-center align-items-center gap-4">
              <img
                src="/assets/images/Rolling-1s-200px.svg"
                style={{ height: "125px" }}
              />
            </div>
          ) : (
            <div className="p-4">
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Pending</h4>
                <p>{orderReportData?.pending || 0}</p>
              </div>
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Processing</h4>
                <p>{orderReportData?.processing || 0}</p>
              </div>
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Shipped + Delivered</h4>
                <p>
                  {(orderReportData?.shipped || 0) +
                    (orderReportData?.delivered || 0) || 0}
                </p>
              </div>
              <div className="d-inner-card">
                <h4 className="d-inner-card-heading">Cancelled + Rejected</h4>
                <p>
                  {(orderReportData?.cancelled || 0) +
                    (orderReportData?.rejected || 0) || 0}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </MyAccountLayout>
  );
};

export default Dashboard;
