import React, { useEffect, useState } from "react";
import OrderSummary from "../components/Checkout/OrderSummary";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { clearCart, fetchCartItems } from "../redux/CartSlice";
import { toast } from "react-toastify";

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items } = useSelector((state) => state.cart);
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [activeTab, setActiveTab] = useState("tab_one");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cashon");
  const [formData, setFormData] = useState({
    country: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
  });
  const [sdkReady, setSdkReady] = useState(true);

  useEffect(() => {
    const addPaypalScript = async () => {
      const { data: clientId } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/config/getPaypalClient`
      );
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;

      script.onload = () => {
        setSdkReady(true);
      };

      document.body.appendChild(script);
    };

    if (!window.paypal) addPaypalScript();
    else setSdkReady(true);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/addresses`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setAddresses(res.data);
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  const validateAddress = () => {
    if (
      activeTab === "tab_one" &&
      (!formData.country ||
        !formData.line1 ||
        !formData.city ||
        !formData.postalCode)
    ) {
      toast("Please enter a valid address");
      return false;
    } else if (activeTab === "tab_two" && !selectedAddress) {
      toast("Please select an address");
      return false;
    }

    return true;
  };

  const handleFormSubmit = () => {
    let paymentMethod;
    switch (selectedPaymentMethod) {
      case "paypalmode":
        paymentMethod = "Paypal";
        break;
      default:
        paymentMethod = "Cash On Delivery";
        break;
    }

    const checkoutData = {
      paymentMethod,
      selectedCurr: selectedCurrency._id,
    };

    if (activeTab === "tab_one") {
      checkoutData.shippingAddress = formData;
    } else {
      checkoutData.addressId = selectedAddress;
    }

    let config = {
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}/order`,
      headers: {
        Authorization: token,
      },
      data: checkoutData,
    };

    axios
      .request(config)
      .then((res) => {
        dispatch(clearCart());
        navigate("/shop");
      })
      .catch((error) => {
        toast.error("Failed to place order");
      });
  };

  const handleAddressChange = (event) => {
    const selectedAdd = event.target.value;
    setSelectedAddress(selectedAdd);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setSelectedAddress(addresses && addresses[0]?._id);
  }, [addresses]);

  return (
    <>
      <Breadcrumbs />
      <main className="site-main main-container no-sidebar">
        <div className="container">
          <div className="row">
            <div className="main-content col-md-12">
              <div className="page-main-content">
                <div className="kaycee">
                  <div className="kaycee-notices-wrapper"></div>
                  <form name="checkout" className="checkout kaycee-checkout">
                    <div className="col2-set" id="customer_details">
                      <ul
                        className="tabs dreaming-tabs"
                        style={{
                          marginBottom: "30px",
                          textAlign: "left",
                        }}
                      >
                        <li
                          className={`description_tab ${
                            activeTab === "tab_one" ? "active" : ""
                          }`}
                          style={{ padding: "0 2px" }}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTab("tab_one");
                            }}
                            style={{ margin: "0" }}
                          >
                            new address
                          </a>
                        </li>
                        <li
                          className={`additional_information_tab ${
                            activeTab === "tab_two" ? "active" : ""
                          }`}
                          style={{ padding: "0 2px" }}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setActiveTab("tab_two");
                            }}
                            style={{ margin: "0" }}
                          >
                            your addresses
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content reviews-tab">
                        <div
                          className={`tab-pane fade   ${
                            activeTab === "tab_one" ? "show active" : ""
                          } `}
                          id="tab_one"
                        >
                          <div className="tab-one" style={{ overflow: "auto" }}>
                            <div className="col-1">
                              <div className="kaycee-billing-fields">
                                <div className="kaycee-billing-fields__field-wrapper">
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_company_field"
                                    data-priority="30"
                                  >
                                    <label htmlFor="billing_company">
                                      Country&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <input
                                      type="text"
                                      className="input-text"
                                      id="country"
                                      name="country"
                                      placeholder="Country"
                                      value={formData.country}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_company_field"
                                    data-priority="30"
                                  >
                                    <label htmlFor="billing_company">
                                      Street address&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <input
                                      type="text"
                                      className="input-text"
                                      id="line1"
                                      name="line1"
                                      placeholder="Street address Line 1"
                                      value={formData.line1}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_company_field"
                                    data-priority="30"
                                  >
                                    <input
                                      type="text"
                                      className="input-text"
                                      id="line1"
                                      name="line2"
                                      placeholder="Street address Line 2 (Optional)"
                                      value={formData.line2}
                                      onChange={handleInputChange}
                                    />
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_company_field"
                                    data-priority="30"
                                  >
                                    <label htmlFor="billing_company">
                                      Town / City&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <input
                                      type="text"
                                      className="input-text"
                                      id="city"
                                      name="city"
                                      placeholder="Town / City"
                                      value={formData.city}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_company_field"
                                    data-priority="30"
                                  >
                                    <label htmlFor="billing_company">
                                      State / Division&nbsp;
                                    </label>
                                    <input
                                      type="text"
                                      className="input-text"
                                      id="state"
                                      name="state"
                                      placeholder="State / Division"
                                      value={formData.state}
                                      onChange={handleInputChange}
                                    />
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_company_field"
                                    data-priority="30"
                                  >
                                    <label htmlFor="billing_company">
                                      Postcode / ZIP&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <input
                                      type="number"
                                      className="input-text"
                                      id="postalCode"
                                      name="postalCode"
                                      placeholder="Postcode / ZIP"
                                      value={formData.postalCode}
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade   ${
                            activeTab === "tab_two" ? "show active" : ""
                          } `}
                          id="tab_two"
                        >
                          <div className="tab-two" style={{ overflow: "auto" }}>
                            <div className="col-1">
                              <div className="kaycee-billing-fields">
                                <div className="kaycee-billing-fields__field-wrapper">
                                  <div className="row">
                                    {addresses.length ? (
                                      addresses?.map((address, index) => (
                                        <div className="col-md-6" key={index}>
                                          <address className=" d-flex align-items-center justify-content-center">
                                            <input
                                              type="radio"
                                              id={address?._id}
                                              className="input-radio mr-4"
                                              name="address"
                                              value={address?._id}
                                              checked={
                                                selectedAddress === address._id
                                              }
                                              onChange={handleAddressChange}
                                            />

                                            <label htmlFor={address?._id}>
                                              {address?.line1}
                                              {address?.line2 &&
                                                `, ${address.line2}`}
                                              <br />
                                              {address.city}, {address.state}{" "}
                                              <br />
                                              {address.postalCode},{" "}
                                              {address.country}
                                            </label>
                                          </address>
                                        </div>
                                      ))
                                    ) : (
                                      <>No addresses available</>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3 id="order_review_heading">Your order</h3>
                    <div
                      id="order_review"
                      className="kaycee-checkout-review-order"
                    >
                      <OrderSummary
                        items={items}
                        selectedPaymentMethod={selectedPaymentMethod}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        validateAddress={validateAddress}
                        handleFormSubmit={handleFormSubmit}
                        sdkReady={sdkReady}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CheckoutForm;
