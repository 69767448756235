import React from "react";

const Pagination = ({
  totalPages,
  activePage,
  fetchPage,
  res,
  totalResults,
}) => {
  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  if (totalPages === 0) {
    return <></>;
  }

  return (
    <div className="shop-control shop-after-control">
      <nav className="kaycee-pagination">
        {activePage > 1 && (
          <a
            className="prev page-numbers"
            href="#"
            onClick={() => fetchPage(activePage - 1)}
          >
            Prev
          </a>
        )}
        {generatePageNumbers().map((pageNumber) => (
          <React.Fragment key={pageNumber}>
            {pageNumber === activePage ? (
              <span className="page-numbers current">{pageNumber}</span>
            ) : (
              <a
                className="page-numbers"
                href="#"
                onClick={() => fetchPage(pageNumber)}
              >
                {pageNumber}
              </a>
            )}
          </React.Fragment>
        ))}
        {activePage < totalPages && (
          <a
            className="next page-numbers"
            href="#"
            onClick={() => fetchPage(activePage + 1)}
          >
            Next
          </a>
        )}
      </nav>
      <p className="kaycee-result-count">
        Showing {res.from}-{res.to} of {totalResults} results
      </p>
    </div>
  );
};

export default Pagination;
