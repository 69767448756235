import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoHome, IoHomeOutline } from "react-icons/io5";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { HiOutlineShoppingCart, HiShoppingCart } from "react-icons/hi";
import { BsPerson, BsPersonFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AiFillShop, AiOutlineShop } from "react-icons/ai";

const MobileFooter = () => {
  const { role } = useSelector((state) => state.user);
  const location = useLocation();
  const links = [
    {
      name: "home",
      url: "/",
      icon: IoHomeOutline,
      activeIcon: IoHome,
      text: "Home",
    },
    {
      name: "shop",
      url: "/shop",
      icon: AiOutlineShop,
      activeIcon: AiFillShop,
      text: "Shop",
    },
    {
      name: "wishlist",
      url: "/wishlist",
      icon: GoHeart,
      activeIcon: GoHeartFill,
      text: "Wishlist",
    },
    {
      name: "cart",
      url: "/cart",
      icon: HiOutlineShoppingCart,
      activeIcon: HiShoppingCart,
      text: "Cart",
      count: 0,
    },
    // {
    //   name: "user",
    //   url: role === "customer" ? "/my-account/dashboard" : "/dashboard",
    //   icon: BsPerson,
    //   activeIcon: BsPersonFill,
    //   text: "Account",
    // },
  ];

  return (
    <div className="footer-device-mobile" style={{ maxWidth: "100vw" }}>
      <div className="wapper">
        {links.map((link, index) => (
          <div
            className={`footer-device-mobile-item device-${link.name}`}
            key={index}
          >
            <Link to={link.url}>
              <span className="icon">
                {/* <span className={link.icon}></span> */}
                {location.pathname === link.url ? (
                  <link.activeIcon />
                ) : (
                  <link.icon />
                )}
                {link.count ? (
                  <span className="count-icon">{link.count}</span>
                ) : (
                  <></>
                )}
              </span>
              <span className="text">{link.text}</span>
            </Link>
          </div>
        ))}
      </div>
      <a href="#" className="backtotop active">
        <i className="flaticon-right-arrow"></i>
      </a>
    </div>
  );
};

export default MobileFooter;
