import axios from "axios";
import React, { useEffect, useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import formatPriceWithCommas from "../PriceFormatter";

const OrderSummary = ({
  items,
  handleFormSubmit,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  sdkReady,
  validateAddress,
}) => {
  const paymentMethods = [
    {
      id: "cashon",
      label: "Cash On Delivery",
      description: "Pay with cash upon delivery.",
    },
    {
      id: "paypalmode",
      label: "Pay with PayPal",
      description:
        "Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account.",
    },
  ];
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [calculatedSubTotal, setCalculatedSubTotal] = useState(0);
  const [userDiscountData, setUserDiscountData] = useState([]);
  const [productsDiscount, setProductsDiscount] = useState(0);
  const [userDiscount, setUserDiscount] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState("cashon");

  const handlePaymentMethodChange = (event) => {
    const selectedMethod = event.target.value;
    if (!validateAddress()) return false;
    setSelectedPaymentMethod(selectedMethod);
  };

  const fetchConversionRate = async () => {
    try {
      const response = await axios.get(
        `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${selectedCurrency.code.toLowerCase()}.json`
      );
      const { [selectedCurrency.code.toLowerCase()]: rates } = response.data;
      const converted =
        (calculatedSubTotal - productsDiscount - userDiscount) * rates.usd;
      setConvertedAmount(converted);
    } catch (error) {
      console.log("Error fetching exchange rates:", error);
    }
  };

  const fetchUserDiscounts = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/getUserDiscounts`,
      {},
      { headers: { Authorization: token } }
    );
    setUserDiscountData(response?.data?.discounts);
  };

  useEffect(() => {
    fetchUserDiscounts();
  }, [token]);

  useEffect(() => {
    const itemsTotal = items?.reduce(
      (acc, item) =>
        acc +
        item?.totalPrice?.find((p) => p.currType === selectedCurrency._id)
          ?.amount,
      0
    );
    setCalculatedSubTotal(itemsTotal);

    const itemsDiscount = items.reduce((acc, item) => {
      if (item?.product?.discounts?.length > 0) {
        const itemTotalPrice = item?.totalPrice?.find(
          (p) => p.currType === selectedCurrency._id
        )?.amount;
        const applicableDiscounts = item?.product?.discounts.filter(
          (discount) =>
            new Date().getTime() <= new Date(discount.timeLimit).getTime()
        );

        applicableDiscounts.forEach((applicableDiscount) => {
          const selectedCurrenciesDiscount = applicableDiscount.value.find(
            (value) => value.currType._id == selectedCurrency._id
          );

          if (applicableDiscount.discountType === "fixed") {
            return (acc += selectedCurrenciesDiscount.amount);
          } else if (applicableDiscount.discountType === "percentage") {
            return (acc +=
              (selectedCurrenciesDiscount.amount / 100) * itemTotalPrice);
          }
        });
      }
      return acc;
    }, 0);

    setProductsDiscount(itemsDiscount);

    const applicableUserDiscounts = userDiscountData.filter(
      (discount) =>
        new Date().getTime() <= new Date(discount.timeLimit).getTime()
    );

    let userTotalDiscount = 0;

    applicableUserDiscounts.forEach((userDiscount) => {
      const selectedCurrenciesDiscount = userDiscount.value.find(
        (value) => value.currType._id === selectedCurrency._id
      );
      if (userDiscount.discountType === "fixed") {
        userTotalDiscount += selectedCurrenciesDiscount.amount;
      } else if (userDiscount.discountType === "percentage") {
        userTotalDiscount +=
          (selectedCurrenciesDiscount?.amount / 100) *
          (itemsTotal - itemsDiscount);
      }
    });

    setUserDiscount(userTotalDiscount);

    fetchConversionRate();
  }, [items, selectedCurrency, userDiscountData]);

  return (
    <>
      {
        <table className="shop_table kaycee-checkout-review-order-table">
          <thead>
            <tr>
              <th className="product-name">Product</th>
              <th className="product-total">Total</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item, index) => (
              <tr key={index} className="cart_item">
                <td className="product-name">
                  {item?.product?.title}&nbsp;&nbsp;
                  <strong className="product-quantity">
                    x {item?.quantity}
                  </strong>
                </td>
                <td className="product-total">
                  <span className="kaycee-Price-amount amount">
                    <span className="kaycee-Price-currencySymbol">
                      {formatPriceWithCommas(
                        item?.totalPrice
                          ?.find((p) => p.currType === selectedCurrency._id)
                          ?.amount?.toFixed(2)
                      ) || "Not Available"}
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
          {calculatedSubTotal ? (
            <tfoot>
              <tr className="cart-subtotal">
                <th>Subtotal</th>
                <td>
                  <span className="kaycee-Price-amount amount">
                    <span className="kaycee-Price-currencySymbol">
                      {formatPriceWithCommas(calculatedSubTotal.toFixed(2))}
                    </span>
                  </span>
                </td>
              </tr>
              {/* {console.log(productsDiscount)} */}
              {productsDiscount ? (
                <tr className="cart-subtotal">
                  <th>Total Product Discount</th>
                  <td>
                    <span className="kaycee-Price-amount amount">
                      <span className="kaycee-Price-currencySymbol">
                        -{" "}
                        {formatPriceWithCommas(productsDiscount.toFixed(2)) ||
                          "Not Available"}
                      </span>
                    </span>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {userDiscount ? (
                <tr className="cart-subtotal">
                  <th>Total User Discount</th>
                  <td>
                    <span className="kaycee-Price-amount amount">
                      <span className="kaycee-Price-currencySymbol">
                        - {formatPriceWithCommas(userDiscount.toFixed(2))}
                      </span>
                    </span>
                  </td>
                </tr>
              ) : (
                <></>
              )}
              <tr className="order-total">
                <th>Total</th>
                <td>
                  <strong>
                    <span className="kaycee-Price-amount amount">
                      <span className="kaycee-Price-currencySymbol">
                        {selectedCurrency.symbol}{" "}
                        {formatPriceWithCommas(
                          (
                            calculatedSubTotal -
                            productsDiscount -
                            userDiscount
                          ).toFixed(2)
                        )}
                      </span>
                    </span>
                  </strong>
                </td>
              </tr>
            </tfoot>
          ) : (
            <></>
          )}
        </table>
      }
      <div id="payment" className="kaycee-checkout-payment">
        <div id="payment" className="kaycee-checkout-payment">
          <ul className="wc_payment_methods payment_methods methods">
            {paymentMethods.map((method) => (
              <li
                key={method.id}
                className={`wc_payment_method payment_method_${method.id}`}
              >
                <input
                  id={`payment_method_${method.id}`}
                  type="radio"
                  className="input-radio"
                  name="payment_method"
                  value={method.id}
                  checked={selectedPaymentMethod === method.id}
                  onChange={handlePaymentMethodChange}
                />
                <label htmlFor={`payment_method_${method.id}`}>
                  {method.label}
                </label>
                <div
                  className={`payment_box payment_method_${method.id}`}
                  style={{
                    display: selectedPayment === method.id ? "block" : "none",
                  }}
                >
                  <p>{method.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="form-row place-order">
          <div className="kaycee-terms-and-conditions-wrapper">
            <div className="kaycee-privacy-policy-text">
              <p>
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our{" "}
                <Link
                  to="/privacy-policy"
                  className="kaycee-privacy-policy-link"
                >
                  privacy policy
                </Link>
                .
              </p>
            </div>
          </div>

          {selectedPaymentMethod === "cashon" && (
            <button
              type="button"
              className="button alt"
              name="kaycee_checkout_place_order"
              id="place_order"
              onClick={() => {
                validateAddress() && handleFormSubmit();
              }}
              disabled={!calculatedSubTotal}
            >
              Place order
            </button>
          )}
          {sdkReady && calculatedSubTotal ? (
            selectedPaymentMethod === "paypalmode" &&
            convertedAmount !== null && (
              <PayPalButton
                amount={convertedAmount !== null && convertedAmount.toFixed(2)}
                onSuccess={handleFormSubmit}
              />
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
