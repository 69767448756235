import { useEffect, useState } from "react";
// import CartTable from "../../Cart/CartTable";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsFillBagHeartFill, BsFillCartXFill } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import pathToUrl from "../PathToUrl";
import CartItem from "../Cart/CartItem";
import { MdFilterAltOff } from "react-icons/md";
import formatPriceWithCommas from "../PriceFormatter";

const UserDetailsTabs = ({
  user,
  cartItems,
  wishlistItems,
  fetchPage,
  fetchUser,
}) => {
  const { token, selectedCurrency, isSuperAdmin } = useSelector(
    (state) => state.user
  );
  const [activeTab, setActiveTab] = useState("tab_one");
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [discounts, setDiscounts] = useState([]);

  const fetchUserDiscounts = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/getUserDiscounts`,
      {},
      { headers: { Authorization: token } }
    );
    setSelectedDiscounts(response?.data?.discounts?.map((d) => d?._id) || []);
  };

  useEffect(() => {
    fetchUserDiscounts();
  }, [user]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/discounts`, {
      headers: { Authorization: token },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setDiscounts(res);
      })
      .catch((err) => {
        toast.error("Failed to Fetch Product");
      });
  }, []);

  const changeRole = async (newRole) => {
    try {
      let response = await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/user/${user?._id}/change-role`,
          { newRole },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Updating Role...",
          success: "Role Changed Successfully!",
          error: "Failed to Change Role.",
        }
      );
      if (!response.data.error) {
        fetchPage();
        fetchUser(user._id);
      }
    } catch (error) {
      toast.error("Failed to Change Role.");
    }
  };

  const discountRemoveHandler = async (discountId) => {
    try {
      await toast.promise(
        axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/user/${user?._id}/removeDiscount`,
          { discountId },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Removing Discount...",
          success: "Discount Removed Successfully!",
          error: "Failed to Remove Discount.",
        }
      );
      setSelectedDiscounts((currDiscounts) =>
        currDiscounts.filter((d) => d !== discountId)
      );
    } catch (error) {
      toast.error("Failed to Remove Discount");
    }
  };

  const addDiscountHandler = async (discountId) => {
    try {
      await toast.promise(
        axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/user/${user?._id}/addDiscountToUser`,
          { discountId },
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Adding Discount...",
          success: "Discount Added Successfully!",
          error: "Failed to Add Discount.",
        }
      );
      setSelectedDiscounts((currDiscounts) => [...currDiscounts, discountId]);
    } catch (error) {
      toast.error("Failed to Add Discount.");
    }
  };

  return (
    <>
      <div className="modal-body">
        <div
          className="product-details-reviews pt-2"
          style={{ padding: "0px" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <div className="product-review-info">
                <ul
                  className="tabs dreaming-tabs d-flex"
                  style={{
                    marginBottom: "15px",
                    textAlign: "left",
                    overflowX: "auto",
                  }}
                >
                  <li
                    className={`description_tab ${
                      activeTab === "tab_one" ? "active" : ""
                    }`}
                    style={{ padding: "0 5px" }}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_one");
                      }}
                      style={{ margin: "0" }}
                    >
                      details
                    </a>
                  </li>
                  <li
                    className={`additional_information_tab ${
                      activeTab === "tab_two" ? "active" : ""
                    }`}
                    style={{ padding: "0 5px" }}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_two");
                      }}
                      style={{ margin: "0" }}
                    >
                      Cart
                    </a>
                  </li>
                  <li
                    className={`additional_information_tab ${
                      activeTab === "tab_three" ? "active" : ""
                    }`}
                    style={{ padding: "0 5px" }}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_three");
                      }}
                      style={{ margin: "0" }}
                    >
                      wishlist
                    </a>
                  </li>
                  <li
                    className={`additional_information_tab ${
                      activeTab === "tab_four" ? "active" : ""
                    }`}
                    style={{ padding: "0 5px" }}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("tab_four");
                      }}
                      style={{ margin: "0" }}
                    >
                      Discounts
                    </a>
                  </li>
                </ul>
                <div className="tab-content reviews-tab">
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_one" ? "show active" : ""
                    } `}
                    id="tab_one"
                  >
                    <div
                      className="tab-one"
                      style={{
                        maxHeight: "59vh",
                        overflowY: "auto",
                      }}
                    >
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td style={{ width: "20%", fontWeight: "500" }}>
                              Full Name
                            </td>
                            <td>{user?.fullName}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "500" }}>Role</td>
                            <td>{user?.role}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "500" }}>E-mail</td>
                            <td>{user?.email}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "500" }}>Contact No.</td>
                            <td>{user?.contact}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_two" ? "show active" : ""
                    } `}
                    id="tab_two"
                  >
                    <div className="tab-two">
                      {cartItems?.length > 0 ? (
                        <div className="section-bg-color">
                          <div
                            className="row"
                            style={{ overflow: "auto", maxHeight: "50vh" }}
                          >
                            <div className="col-lg-12">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="pro-thumbnail">Thumbnail</th>
                                    <th className="pro-title">Product</th>
                                    <th className="pro-price">Price</th>
                                    <th className="pro-quantity">Quantity</th>
                                    <th className="pro-amount">Total Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {cartItems.map((cartItem) => (
                                    <CartItem
                                      item={cartItem}
                                      adminView={true}
                                      key={cartItem._id}
                                    />
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex flex-column justify-content-center align-items-center gap-4"
                          style={{ height: "40vh" }}
                        >
                          <IconContext.Provider value={{ color: "#561f7a" }}>
                            <BsFillCartXFill size={100} />
                          </IconContext.Provider>
                          <p>Nothing in Cart.</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade   ${
                      activeTab === "tab_three" ? "show active" : ""
                    } `}
                    id="tab_three"
                  >
                    {wishlistItems?.length > 0 ? (
                      <div className="section-bg-color">
                        <div
                          className="row"
                          style={{
                            maxHeight: "59vh",
                            overflowY: "auto",
                          }}
                        >
                          <div className="col-lg-12">
                            <div className="cart-table table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th className="pro-thumbnail">Thumbnail</th>
                                    <th className="pro-title">Product</th>
                                    <th className="pro-price">Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {wishlistItems?.map((item) => (
                                    <tr key={item._id}>
                                      <td className="pro-thumbnail">
                                        <img
                                          className="img-fluid"
                                          src={
                                            item.imageUrls &&
                                            pathToUrl(item?.imageUrls[0])
                                          }
                                          style={{ maxHeight: "100px" }}
                                          alt={item.title}
                                        />
                                      </td>
                                      <td className="pro-title">
                                        <Link to={`/${item.slug}`}>
                                          {item.title}
                                        </Link>
                                      </td>
                                      <td className="pro-price">
                                        {selectedCurrency?.symbol}
                                        {formatPriceWithCommas(
                                          item.basePrice.find(
                                            (p) =>
                                              p.currType ===
                                              selectedCurrency._id
                                          )?.amount
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center gap-4"
                        style={{ height: "40vh" }}
                      >
                        <IconContext.Provider value={{ color: "#561f7a" }}>
                          <BsFillBagHeartFill size={100} />
                        </IconContext.Provider>
                        <p>Nothing in Wishlist.</p>
                      </div>
                    )}
                  </div>
                  <div
                    className={`tab-pane fade ${
                      activeTab === "tab_four" ? "show active" : ""
                    } `}
                    id="tab_four"
                  >
                    <div className="tab-five">
                      {discounts?.length > 0 ? (
                        <table className="table ">
                          <tbody>
                            <tr>
                              <td style={{ width: "20%" }}>
                                Selected Discounts
                              </td>
                              <td>
                                {selectedDiscounts?.map((discount, ind) => (
                                  <span
                                    key={discount}
                                    className="discount_tags discount_tags_selected "
                                    onClick={() => {
                                      discountRemoveHandler(discount);
                                    }}
                                  >
                                    {
                                      discounts?.find(
                                        (d) => d?._id === discount
                                      )?.title
                                    }
                                  </span>
                                ))}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: "20%" }}>
                                Available Discounts
                              </td>
                              <td>
                                {discounts?.map((discount, ind) => {
                                  if (selectedDiscounts.includes(discount?._id))
                                    return "";
                                  return (
                                    <span
                                      key={discount?._id}
                                      className="discount_tags "
                                      onClick={() => {
                                        addDiscountHandler(discount?._id);
                                      }}
                                    >
                                      {discount?.title}
                                    </span>
                                  );
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <div
                          className="d-flex flex-column justify-content-center align-items-center gap-4"
                          style={{ height: "40vh" }}
                        >
                          <IconContext.Provider value={{ color: "#561f7a" }}>
                            <MdFilterAltOff size={100} />
                          </IconContext.Provider>
                          <p>
                            No Filter found. Please create filters from filters
                            page.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer" style={{ textTransform: "capitalize" }}>
        {user.role !== "admin" && isSuperAdmin && (
          <button
            className="button btn themeBtn"
            onClick={() => changeRole("admin")}
          >
            Give Admin Permissions
          </button>
        )}
        {user.role === "admin" && !user.isSuperAdmin && isSuperAdmin && (
          <button
            className="button btn themeBtn"
            onClick={() => changeRole("customer")}
          >
            Revoke Admin Permissions
          </button>
        )}
      </div>
    </>
  );
};

export default UserDetailsTabs;
