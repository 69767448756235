import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addToWishlist } from "../../redux/WishlistSlice";
import pathToUrl from "../PathToUrl";
import formatPriceWithCommas from "../PriceFormatter";

const ProductItem = ({
  _id,
  slug,
  imageUrls,
  title,
  basePrice,
  description,
  discount,
  isNew,
  isGridMode,
}) => {
  const dispatch = useDispatch();
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const primaryImage = imageUrls && `${imageUrls[0]}`;
  const [basePriceInSelectedCurrecy, setBasePriceInSelectedCurrecy] =
    useState(0);

  useEffect(() => {
    let selectedCurrencyBasePrice = basePrice?.find(
      (p) => p?.currType?._id == selectedCurrency._id
    );
    setBasePriceInSelectedCurrecy(selectedCurrencyBasePrice);
  }, [selectedCurrency, _id]);

  const addItem = async () => {
    try {
      await toast.promise(dispatch(addToWishlist({ token, id: _id })), {
        pending: "Adding to Wishlist...",
        success: "Item added to Wishlist!",
        error: "Failed to add item to Wishlist.",
      });
    } catch (error) {
      toast.error("Failed to add item to Wishlist");
    }
  };
  // col-bg-3 col-xl-3 col-lg-5 col-md-5 col-sm-5 col-ts-10
  return (
    <li
      className={
        isGridMode
          ? "product-item wow fadeInUp product-item rows-space-30 col-bg-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-ts-6 style-01 post-28 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-sock instock sale featured shipping-taxable purchasable product-type-simple"
          : "product-item wow fadeInUp product-item rows-space-30 post-28 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-sofas product_tag-light product_tag-sock instock sale featured shipping-taxable purchasable product-type-simple col-md-12 list "
      }
      data-wow-duration="1s"
      data-wow-delay="0ms"
      data-wow="fadeInUp"
    >
      <div className="product-inner tooltip-left product_Item px-3">
        <div className="product-thumb">
          <Link
            className="thumb-link d-flex justify-content-center align-items-center py-2"
            to={`/${slug}`}
            style={isGridMode ? { height: "180px" } : {}}
          >
            <img
              className="img-responsive"
              src={pathToUrl(primaryImage)}
              style={{ height: "90%" }}
              alt={title}
            />
          </Link>
          <div className="flash">
            {isNew && (
              <span className="onnew">
                <span className="text">New</span>
              </span>
            )}
            {/* {salePrice && (
              <span className="onsale">
                <span className="number">-14%</span>
              </span>
            )} */}
          </div>
          <Link
            to={`/${slug}`}
            className="button yith-wcqv-button"
            data-product_id="24"
          >
            Quick View
          </Link>
          <div className="group-button">
            <div className="yith-wcwl-add-to-wishlist">
              <div className="yith-wcwl-add-button show">
                <Link onClick={() => addItem()} className="add_to_wishlist">
                  Add to Wishlist
                </Link>
              </div>
            </div>
            <Link to={`/${slug}`} className="button yith-wcqv-button">
              Quick View
            </Link>
          </div>
        </div>
        <div className="product-info equal-elem">
          <h3 className="product-name product_title">
            <Link to={`/${slug}`}>{title}</Link>
          </h3>
          {/* <div className="rating-wapper">
            <div className="star-rating">
              <span style={{ width: `${(rating / 5) * 100}%` }}>
                Rated <strong className="rating">{rating}</strong> out of 5
              </span>
            </div>
            <span className="review">({reviews})</span>
          </div> */}
          <span className="price">
            {/* {regularPrice && (
              <del className="kaycee-Price-amount amount">
                <span className="kaycee-Price-currencySymbol">₹</span>
                {regularPrice}
              </del>
            )}
            {salePrice && (
              <ins className="kaycee-Price-amount amount">
                <span className="kaycee-Price-currencySymbol">₹</span>
                {salePrice}
              </ins>
            )} */}
            {basePrice && (
              <ins className="kaycee-Price-amount amount">
                <span className="kaycee-Price-currencySymbol">
                  {basePriceInSelectedCurrecy?.currType?.symbol}
                </span>{" "}
                {formatPriceWithCommas(
                  basePriceInSelectedCurrecy?.amount?.toFixed(2) || 0
                )}
              </ins>
            )}
          </span>
          <div className="kaycee-product-details__short-description">
            <p>{description}</p>
          </div>
        </div>
        <div className="group-button">
          <div className="group-button-inner">
            <div className="yith-wcwl-add-to-wishlist">
              <div className="yith-wcwl-add-button show">
                <Link onClick={() => addItem()} className="add_to_wishlist">
                  Add to Wishlist
                </Link>
              </div>
            </div>

            <Link to={`/${slug}`} className="button yith-wcqv-button">
              Quick View
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductItem;
