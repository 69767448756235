import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartItems } from "../redux/CartSlice";
import CartItem from "../components/Cart/CartItem";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { BsFillCartXFill } from "react-icons/bs";
import formatPriceWithCommas from "../components/PriceFormatter";

const Cart = () => {
  const dispatch = useDispatch();
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const { items } = useSelector((state) => state.cart);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    dispatch(fetchCartItems(token));
  }, []);

  useEffect(() => {
    setTotalPrice(
      items
        ?.reduce(
          (acc, item) =>
            acc +
            item.totalPrice.find((p) => p.currType === selectedCurrency._id)
              ?.amount,
          0
        )
        ?.toFixed(2)
    );
  }, [items, selectedCurrency]);

  return (
    <>
      <Breadcrumbs />
      {items?.length > 0 ? (
        <main className="site-main main-container no-sidebar">
          <div className="container">
            <div className="row">
              <div className="main-content col-md-12">
                <div className="page-main-content">
                  <div className="kaycee">
                    <form className="kaycee-cart-form">
                      <table
                        className="shop_table shop_table_responsive cart kaycee-cart-form__contents"
                        cellSpacing="0"
                        style={{ overflowX: "auto" }}
                      >
                        <tbody>
                          {items?.map((item) => (
                            <CartItem item={item} key={item._id} />
                          ))}
                        </tbody>
                      </table>
                    </form>
                    <div className="cart-collaterals">
                      <div className="cart_totals ">
                        <h2>Cart totals</h2>
                        <table
                          className="shop_table shop_table_responsive"
                          cellSpacing="0"
                        >
                          <tbody>
                            <tr className="cart-subtotal">
                              <th>Subtotal</th>
                              <td data-title="Subtotal">
                                <span className="kaycee-Price-amount amount">
                                  <span className="kaycee-Price-currencySymbol">
                                    {selectedCurrency.symbol}{" "}
                                  </span>
                                  {formatPriceWithCommas(totalPrice || 0)}
                                </span>
                              </td>
                            </tr>
                            {/* <tr className="order-total">
                            <th>Total</th>
                            <td data-title="Total">
                              <strong>
                                <span className="kaycee-Price-amount amount">
                                  <span className="kaycee-Price-currencySymbol">
                                    {selectedCurrency.symbol}
                                  </span>
                                  {calculateSubtotal().toFixed(2)}
                                </span>
                              </strong>
                            </td>
                          </tr> */}
                          </tbody>
                        </table>
                        <div className="kaycee-proceed-to-checkout">
                          <Link
                            to={"/checkout"}
                            className="checkout-button button alt kaycee-forward"
                          >
                            Proceed to checkout
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh" }}
        >
          <IconContext.Provider value={{ color: "#561f7a" }}>
            <BsFillCartXFill size={100} />
          </IconContext.Provider>
          <p>
            Nothing in Cart. Please select from the{" "}
            <Link to="/shop" style={{ color: "#561f7a" }}>
              Shop
            </Link>
            .
          </p>
        </div>
      )}
    </>
  );
};

export default Cart;
