import React, { useEffect, useState } from "react";
import LoginRegisterLayout from "../layouts/LoginRegister";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../redux/UserSlice";
import { toast } from "react-toastify";
import axios from "axios";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });

  useEffect(() => {
    if (auth) {
      return navigate("/shop");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.username || !formData.password) {
      toast.error("Please enter your username and password");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/login`,
          {
            uid: formData.username,
            password: formData.password,
          }
        );

        if (response.data.token) {
          dispatch(
            login({
              auth: true,
              name: response.data.fullName,
              isSuperAdmin: response.data.isSuperAdmin,
              role: response.data.role,
              token: response.data.token,
            })
          );
          if (response.data.role === "admin") {
            navigate("/dashboard");
          } else {
            navigate("/shop");
          }
          toast.success("Login successful!");
        } else {
          toast.error("Internal server error");
        }
      } catch (error) {
        toast.error("Invalid credentials. Please try again.");
      }
    }
  };

  return (
    <LoginRegisterLayout>
      <div
        className="u-column1 col-1 mx-auto"
        style={{ paddingTop: "10px", margin: "20px 0" }}
      >
        <h2 style={{ marginBottom: 0 }}>Login</h2>
        <form
          className="kaycee-form kaycee-form-login login"
          onSubmit={handleSubmit}
        >
          <p className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide">
            <label htmlFor="username">
              Username or email address&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="kaycee-Input kaycee-Input--text input-text"
              name="username"
              id="username"
              autoComplete="username"
              value={formData.username}
              onChange={handleInputChange}
            />
          </p>
          <p className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide">
            <label htmlFor="password">
              Password&nbsp;<span className="required">*</span>
            </label>
            <input
              className="kaycee-Input kaycee-Input--text input-text"
              type="password"
              name="password"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </p>
          <p className="form-row">
            <input
              type="hidden"
              id="kaycee-login-nonce"
              name="kaycee-login-nonce"
              value="832993cb93"
            />
            <input
              type="hidden"
              name="_wp_http_referer"
              value="/kaycee/my-account/"
            />
            <button
              type="submit"
              className="kaycee-Button button"
              name="login"
              value="Log in"
            >
              Log in
            </button>
            {/* <label className="kaycee-form__label kaycee-form__label-for-checkbox inline">
              <input
                className="kaycee-form__input kaycee-form__input-checkbox"
                name="rememberMe"
                type="checkbox"
                id="rememberme"
                checked={formData.rememberMe}
                onChange={handleInputChange}
              />
              <span>Remember me</span>
            </label> */}
          </p>
          <p className="kaycee-LostPassword lost_password">
            <Link to="/register">Haven't Registered Yet?</Link>
          </p>
        </form>
      </div>
    </LoginRegisterLayout>
  );
};

export default Login;
