import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const HeroSlider = () => {
  const slideData = [
    {
      imageSrc: "/assets/images/slide31.jpg",
      title: "The Summer",
      subtitle: "Lookbook",
      description: "Beauty & Cool",
      buttonLabel: "Shop now",
      buttonLink: "/shop",
    },
    {
      imageSrc: "/assets/images/slide33.jpg",
      title: "The Summer",
      subtitle: "Lookbook",
      description: "Beauty & Cool",
      buttonLabel: "Shop now",
      buttonLink: "/shop",
    },
    {
      imageSrc: "/assets/images/slide32.jpg",
      title: "The Summer",
      subtitle: "Lookbook",
      description: "Beauty & Cool",
      buttonLabel: "Shop now",
      buttonLink: "/shop",
    },
  ];

  const settings = {
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    speed: 300,
    autoplay: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slide-home-03">
      <Slider {...settings}>
        {slideData.map((slide, i) => (
          <div className="slide-wrap" key={i}>
            <img src={slide.imageSrc} alt="image" />
            <div className="slide-info">
              <div className="container">
                <div className="slide-inner">
                  <h1>{slide.title}</h1>
                  <h5>{slide.subtitle}</h5>
                  <h2>
                    <span>{slide.description}</span>
                  </h2>
                  <Link to={slide.buttonLink}>{slide.buttonLabel}</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;
