import React, { useEffect, useState } from "react";
import LoginRegisterLayout from "../layouts/LoginRegister";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { login } from "../redux/UserSlice";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (auth) {
      return navigate("/shop");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.password.length < 8 ||
      formData.password !== formData.confirmPassword
    ) {
      toast.error("Please enter a valid password");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/register`,
          {
            fullName: formData.fullName,
            email: formData.email,
            contact: formData.contact,
            password: formData.password,
          }
        );

        if (response.data.token) {
          dispatch(
            login({
              auth: true,
              name: response.data.name,
              isSuperAdmin: response.data.isSuperAdmin,
              role: response.data.role,
              token: response.data.token,
            })
          );
          navigate("/shop");
          toast.success("Registration successful!");
        } else {
          toast.error("Internal server error");
        }
      } catch (error) {
        if (error.response.data.message === "User already exists") {
          toast.error("User already exists with email or contact");
        } else {
          toast.error("Failed to register. Please try again.");
        }
      }
    }
  };

  return (
    <LoginRegisterLayout>
      <div
        className="u-column2 col-2 mx-auto"
        style={{ paddingTop: "10px", margin: "20px 0" }}
      >
        <h2 style={{ marginBottom: 0 }}>Register</h2>
        <form
          method="post"
          className="kaycee-form kaycee-form-register register"
          onSubmit={handleSubmit}
        >
          <p
            className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide"
            style={{ marginBottom: "10px" }}
          >
            <label htmlFor="reg_email">
              Full Name&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="kaycee-Input kaycee-Input--text input-text"
              name="fullName"
              id="reg_fullName"
              autoComplete="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
            />
          </p>
          <p
            className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide"
            style={{ marginBottom: "10px" }}
          >
            <label htmlFor="reg_email">
              Email address&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="email"
              className="kaycee-Input kaycee-Input--text input-text"
              name="email"
              id="reg_email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </p>
          <p
            className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide"
            style={{ marginBottom: "10px" }}
          >
            <label htmlFor="reg_email">
              Contact No.&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="text"
              className="kaycee-Input kaycee-Input--text input-text"
              name="contact"
              id="reg_contact"
              autoComplete="contact"
              value={formData.contact}
              onChange={handleInputChange}
            />
          </p>
          <p
            className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide"
            style={{ marginBottom: "10px" }}
          >
            <label htmlFor="reg_password">
              Password&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="password"
              className="kaycee-Input kaycee-Input--text input-text"
              name="password"
              id="reg_password"
              autoComplete="new-password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </p>
          <p
            className="kaycee-form-row kaycee-form-row--wide form-row form-row-wide"
            style={{ marginBottom: "10px" }}
          >
            <label htmlFor="confirm_password">
              Confirm Password&nbsp;
              <span className="required">*</span>
            </label>
            <input
              type="password"
              className="kaycee-Input kaycee-Input--text input-text"
              name="confirmPassword"
              id="confirm_password"
              autoComplete="new-password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
          </p>
          <div className="kaycee-privacy-policy-text">
            <p>
              Your personal data will be used to support your experience
              throughout this website, to manage access to your account, and for
              other purposes described in our{" "}
              <Link
                to="/privacy-&-policy"
                className="kaycee-privacy-policy-link"
              >
                privacy policy
              </Link>
              .
            </p>
          </div>
          <p className="kaycee-FormRow form-row">
            <button
              type="submit"
              className="kaycee-Button button"
              name="register"
              value="Register"
            >
              Register
            </button>
          </p>
          <p className="kaycee-LostPassword lost_password">
            <Link to="/login">Already have account?</Link>
          </p>
        </form>
      </div>
    </LoginRegisterLayout>
  );
};

export default Register;
