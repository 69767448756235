import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomerRoutes = () => {
  let { auth, role } = useSelector((state) => state.user);
  return role === "customer" && auth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default CustomerRoutes;
