import React from "react";
import { useLocation, Link } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((pathname) => pathname);

  return (
    <div className="banner-wrapper has_background">
      <img
        src="/assets/images/banner-for-all2.jpg"
        className="img-responsive attachment-1920x447 size-1920x447"
        alt="img"
      />
      <div className="banner-wrapper-inner">
        <h1 className="page-title" style={{ textTransform: "capitalize" }}>
          {pathnames.length > 0
            ? pathnames[pathnames.length - 1].split("-").join(" ")
            : "Home"}
        </h1>
        <div
          role="navigation"
          aria-label="Breadcrumbs"
          className="breadcrumb-trail breadcrumbs"
        >
          <ul className="trail-items breadcrumb">
            <li className="trail-item trail-begin">
              <Link to="/">
                <span>Home</span>
              </Link>
            </li>
            {pathnames?.map((pathname, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              return (
                <li
                  className={`trail-item ${isLast ? "trail-end active" : ""}`}
                  key={index}
                  style={{ textTransform: "capitalize" }}
                >
                  {isLast ? (
                    <span>{pathname.split("-").join(" ")}</span>
                  ) : (
                    <Link to={routeTo}>
                      <span>{pathname.split("-").join(" ")}</span>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
