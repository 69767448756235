"use client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logout } from "./UserSlice";
import { clearCart } from "./CartSlice";

export const fetchWishlistItems = createAsyncThunk(
  "fetchWishlistItems",
  async (token, thunkAPI) => {
    if (!token) return [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/wishlist`,
        {
          headers: { Authorization: token },
        }
      );
      return response.data.items;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logout());
        thunkAPI.dispatch(clearCart());
        thunkAPI.dispatch(clearWishlist());
      }
      throw error.response.data;
    }
  }
);

export const addToWishlist = createAsyncThunk(
  "addToWishlist",
  async ({ token, id }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/wishlist/${id}`,
        {},
        {
          headers: { Authorization: token },
        }
      );
      return response.data.items;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const removeFromWishlist = createAsyncThunk(
  "removeFromWishlist",
  async ({ token, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/wishlist/${id}`,
        {
          headers: { Authorization: token },
        }
      );
      return response.data.items;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const WishlistSlice = createSlice({
  name: "wishlist",
  initialState: { items: [], status: "idle", error: null, wishlistLength: 0 },
  reducers: {
    clearWishlist: (state, action) => {
      state.items = [];
      state.status = "idle";
      state.error = null;
      state.wishlistLength = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToWishlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.wishlistLength = action?.payload?.length;
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeFromWishlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromWishlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.wishlistLength = action?.payload?.length;
      })
      .addCase(removeFromWishlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchWishlistItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWishlistItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.wishlistLength = action?.payload?.length;
      })
      .addCase(fetchWishlistItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default WishlistSlice.reducer;

export const { clearWishlist } = WishlistSlice.actions;
