import React, { useEffect, useState } from "react";
import pathToUrl from "../PathToUrl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { removeFromCart, updateCartItem } from "../../redux/CartSlice";
import { Link } from "react-router-dom";
import formatPriceWithCommas from "../PriceFormatter";

const CartItem = ({ item, adminView }) => {
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(item?.quantity);
  const dispatch = useDispatch();
  useEffect(() => {
    setPrice(
      (
        item?.totalPrice.find((p) => p.currType === selectedCurrency._id)
          ?.amount / item?.quantity
      )?.toFixed(2)
    );
    setQuantity(item?.quantity);
  }, [item, selectedCurrency]);

  const onRemove = async (id) => {
    try {
      await toast.promise(dispatch(removeFromCart({ token, id })), {
        pending: "Removing item...",
        success: "Item removed successfully!",
        error: "Failed to remove item.",
      });
    } catch (error) {
      toast.error("Failed to remove item. Please try again.");
    }
  };

  const changeQuantity = (id, quantity) => {
    if (quantity < 1) {
      dispatch(removeFromCart({ token, id }));
    } else {
      dispatch(updateCartItem({ token, id, updatedData: { quantity } }));
    }
  };

  return (
    <tr key={item?._id} className="kaycee-cart-form__cart-item cart_item">
      {!adminView && (
        <td className="product-remove">
          <a
            href="#"
            className="remove"
            aria-label="Remove this item"
            onClick={() => onRemove(item?._id)}
          >
            x
          </a>
        </td>
      )}
      <td className="product-thumbnail">
        <a href="#">
          <img
            src={
              item?.product?.imageUrls && pathToUrl(item?.product?.imageUrls[0])
            }
            alt="img"
            style={{ height: "100px" }}
          />
        </a>
      </td>
      <td className="product-name" data-title="Product" style={{minWidth:"100px"}}>
        <Link to={`/${item?.product?.slug}`}>
          {item?.product?.title} -{" "}
          {item?.options.reduce(
            (acc, option, ind) =>
              acc +
              option.selectedOption.name +
              (ind !== item?.options?.length - 1 ? ", " : ""),
            ""
          )}
        </Link>
      </td>
      <td className="product-price" data-title="Price">
        <span className="kaycee-Price-amount amount">
          <span className="kaycee-Price-currencySymbol">
            {selectedCurrency.symbol}{" "}
          </span>

          {formatPriceWithCommas(price)}
        </span>
      </td>
      <td className="product-quantity" data-title="Quantity">
        <div className="quantity">
          <span className="qty-label">Quantity:</span>
          <div className="control">
            {!adminView && (
              <span
                className="btn-number qtyminus quantity-minus"
                href="#"
                onClick={() => changeQuantity(item?._id, quantity - 1)}
              >
                -
              </span>
            )}
            <input
              type="number"
              value={item?.quantity}
              title="Qty"
              className="input-qty input-text text"
              readOnly
            />
            {!adminView && (
              <span
                className="btn-number qtyplus quantity-plus"
                href="#"
                onClick={() => changeQuantity(item?._id, quantity + 1)}
              >
                +
              </span>
            )}
          </div>
        </div>
      </td>
      <td className="product-subtotal" data-title="Total">
        <span className="kaycee-Price-amount amount">
          <span className="kaycee-Price-currencySymbol">
            {selectedCurrency.symbol}{" "}
          </span>
          {formatPriceWithCommas(
            item?.totalPrice
              .find((p) => p.currType === selectedCurrency._id)
              ?.amount?.toFixed(2)
          )}
        </span>
      </td>
    </tr>
  );
};

export default CartItem;
