import React from "react";
import { Link } from "react-router-dom";

const Footer = ({}) => {
  const socialLinks = [
    { icon: "facebook", link: "https://facebook.com/" },
    { icon: "instagram", link: "https://www.instagram.com/" },
  ];

  const customerInfo = [
    { label: "Shipping & Returns", link: "#" },
    { label: "Secure Shopping", link: "#" },
  ];

  const informationLinks = [
    { label: "Privacy Policy", link: "#" },
    { label: "Customer Service", link: "#" },
  ];

  const copyrightText = "All Rights Reserved.";
  return (
    <footer id="footer" className="footer style-04">
      <div className="section-025">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-6 col-lg-3">
              <div className="kaycee-listitem style-04">
                <div className="listitem-inner">
                  <h4 className="title">Customer</h4>
                  <ul className="listitem-list">
                    {customerInfo.map((info, index) => (
                      <li key={index}>
                        <Link to={info.link} rel="noopener noreferrer">
                          {info.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="logo-footer">
                <img
                  src="/assets/images/logo.png"
                  className="az_single_image-img attachment-full"
                  alt="img"
                  style={{ maxWidth: "250px" }}
                />
              </div>
              <div className="footer-desc">
                We help you sell anything.
                <br />
                The best choice for your next personal
              </div>
              <div className="kaycee-socials style-01">
                <div className="content-socials">
                  <ul className="socials-list">
                    {socialLinks.map((social, index) => (
                      <li key={index}>
                        <Link
                          to={social.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className={`fa fa-${social.icon}`}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="kaycee-listitem style-04">
                <div className="listitem-inner">
                  <h4 className="title">Information</h4>
                  <ul className="listitem-list">
                    {informationLinks.map((info, index) => (
                      <li key={index}>
                        <Link to={info.link} rel="noopener noreferrer">
                          {info.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-016">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                &copy; {new Date().getFullYear()} <Link to="https://brandon.co.in">Brandon Infotech</Link>.{" "}
                {copyrightText}
              </p>
            </div>
            <div className="col-md-6">
              <div className="payment">
                <img
                  src="/assets/images/payment.png"
                  className="az_single_image-img attachment-full"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
