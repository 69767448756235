import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MyAccountLayout from "../../layouts/MyAccount";
import { Link } from "react-router-dom";

const Discounts = () => {
  const { token } = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [discount, setDiscount] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const closeModal = () => {
    setDiscount(null);
    setShowModal(false);
  };

  const fetchAllDiscounts = () => {
    setLoadingStatus(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/discounts`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        setDiscounts(response.data);
        setLoadingStatus(false);
      })
      .catch((error) => {
        toast.error("Failed to fetch discounts");
        setLoadingStatus(false);
      });
  };

  const showDetails = (discountId) => {
    setDiscount(discounts.find((c) => c?._id === discountId));
    setShowModal(true);
  };

  const deleteDiscount = async (discountId) => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/discounts/${discountId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Discount...",
          success: "Discount Deleted Successfully!",
          error: "Failed to Delete Discount.",
        }
      );

      fetchAllDiscounts();
    } catch (error) {
      toast.error("Error deleting discount");
    }
  };

  useEffect(() => {
    fetchAllDiscounts();
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/currencies`)
      .then((response) => {
        setCurrencies(response.data);
      })
      .catch(() => {
        toast.error("Failed to fetch currencies");
      });
  }, []);

  return (
    <>
      <DiscountDetailModal
        showModal={showModal}
        closeModal={closeModal}
        discount={discount}
        setDiscount={setDiscount}
        setDiscounts={setDiscounts}
        currencies={currencies}
      />
      <NewDiscountModal
        showModal={addNewModal}
        closeModal={() => {
          setAddNewModal(false);
        }}
        fetchAllDiscounts={fetchAllDiscounts}
        currencies={currencies}
      />
      <MyAccountLayout>
        <div className="kaycee-MyAccount-content">
          <div className="d-flex align-items-center justify-content-end mb-4 ">
            <button
              className="button alt themeBtn "
              onClick={() => {
                setAddNewModal(true);
              }}
            >
              Add Discount
            </button>
          </div>
          <div className="myaccount-table table-responsive text-center">
            {loadingStatus && (
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-4"
                style={{ height: "80vh" }}
              >
                <img src="/assets/images/Rolling-1s-200px.svg" />
              </div>
            )}
            {!loadingStatus &&
              (discounts?.length <= 0 ? (
                <div className="kaycee-Message kaycee-Message--info kaycee-info">
                  No Discounts available yet.
                </div>
              ) : (
                <table className="table table-bordered ">
                  <thead className="thead-light">
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Discount Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {discounts?.map((discount, index) => {
                      return (
                        <tr key={discount?._id}>
                          <td>{index + 1}</td>
                          <td style={{ textTransform: "capitalize" }}>
                            {discount?.title}
                          </td>

                          <td style={{ textTransform: "capitalize" }}>
                            {discount?.discountType}
                          </td>
                          <td
                            style={{ textTransform: "capitalize", gap: "10px" }}
                            className="d-flex d-sm-table-cell justify-content-center align-items-center flex-wrap"
                          >
                            <Link
                              onClick={() => {
                                showDetails(discount?._id);
                              }}
                              className="btn themeBtn "
                              style={{ marginRight: "10px" }}
                            >
                              Edit
                            </Link>
                            <Link
                              onClick={() => {
                                deleteDiscount(discount?._id);
                              }}
                              className="btn themeBtn "
                            >
                              Delete
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ))}
          </div>
        </div>
      </MyAccountLayout>
    </>
  );
};

const DiscountDetailModal = ({
  showModal,
  closeModal,
  discount,
  setDiscounts,
  currencies,
}) => {
  const { token } = useSelector((state) => state.user);

  const [discountPrices, setDiscountPrices] = useState([]);
  const [updatedDiscountDetails, setUpdatedDiscountDetails] =
    useState(discount);

  useEffect(() => {
    if (discount?.title) {
      const isoDateTime = new Date(discount?.timeLimit);
      const localDateTimeString = isoDateTime.toISOString().slice(0, 16);
      setUpdatedDiscountDetails({
        ...discount,
        timeLimit: localDateTimeString,
      });
    }
  }, [discount]);

  const discountPriceChangeHandler = (id, value) => {
    setDiscountPrices((currDiscountPrices) =>
      currDiscountPrices?.map((discountPrice) => {
        if (discountPrice.currType === id) {
          discountPrice.amount = value;
        }
        return discountPrice;
      })
    );
  };

  const updateDiscount = async () => {
    try {
      const response = await toast.promise(
        axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/discounts/${discount?._id}`,
          { ...updatedDiscountDetails, value: discountPrices },
          { headers: { Authorization: token } }
        ),
        {
          pending: "Updating Discount Details...",
          success: "Discount Details Updated Successfully!",
          error: "Failed to Update Discount Details.",
        }
      );

      setDiscounts((currDiscounts) =>
        currDiscounts?.map((curr) =>
          curr._id === discount?._id ? response.data : curr
        )
      );
    } catch (error) {
      toast.error("Error updating discount");
    }
  };

  useEffect(() => {
    setDiscountPrices([]);
    currencies?.forEach((currency) => {
      setDiscountPrices((currIncRate) => {
        return [
          ...currIncRate,
          {
            currType: currency?._id,
            amount:
              discount?.value?.find((r) => r?.currType?._id === currency?._id)
                ?.amount || 0,
          },
        ];
      });
    });
  }, [currencies, discount]);

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="mt-3 ms-2">{updatedDiscountDetails?.title}</h5>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ overflowX: "auto" }}>
              <table className="table ">
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>Name</td>
                    <td>
                      <input
                        type="text"
                        name="Title"
                        value={updatedDiscountDetails?.title || ""}
                        onChange={(e) =>
                          setUpdatedDiscountDetails((curr) => {
                            return { ...curr, title: e.target.value };
                          })
                        }
                        className="form-control mb-2 discount-input"
                        placeholder="Name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Discount Type</td>
                    <td>
                      <select
                        title="product_cat"
                        name="discountType"
                        className="orderby discount-input"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setUpdatedDiscountDetails((curr) => {
                            return { ...curr, discountType: e.target.value };
                          })
                        }
                      >
                        <option
                          value="fixed"
                          selected={
                            updatedDiscountDetails?.discountType === "fixed"
                          }
                        >
                          Fixed
                        </option>
                        <option
                          value="percentage"
                          selected={
                            updatedDiscountDetails?.discountType ===
                            "percentage"
                          }
                        >
                          Percentage{" "}
                        </option>
                      </select>
                    </td>
                  </tr>
                  {currencies?.map((curr, ind) => (
                    <tr key={curr?._id}>
                      <td>Value ({curr?.symbol})</td>
                      <td>
                        <input
                          type="number"
                          name={curr?._id}
                          value={
                            discountPrices?.find(
                              (p) => p.currType === curr?._id
                            )?.amount || ""
                          }
                          onChange={(e) => {
                            discountPriceChangeHandler(
                              curr?._id,
                              e.target.value
                            );
                          }}
                          className="form-control mb-2 discount-input"
                          placeholder={curr.name}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td style={{ width: "20%" }}>Time Limit</td>
                    <td>
                      <input
                        type="datetime-local"
                        name="timeLimit"
                        value={updatedDiscountDetails?.timeLimit || ""}
                        onChange={(e) =>
                          setUpdatedDiscountDetails((curr) => {
                            return { ...curr, timeLimit: e.target.value };
                          })
                        }
                        className="form-control mb-2 discount-input"
                        placeholder="Symbol"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="modal-footer"
              style={{ textTransform: "capitalize" }}
            >
              <button className="btn themeBtn" onClick={updateDiscount}>
                Update Discount
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewDiscountModal = ({
  showModal,
  closeModal,
  fetchAllDiscounts,
  currencies,
}) => {
  const { token } = useSelector((state) => state.user);
  const [newDiscountDetails, setNewDiscountDetails] = useState({
    title: "",
    discountType: "",
    timeLimit: "",
  });
  const [discountPrices, setDiscountPrices] = useState([]);

  const discountPriceChangeHandler = (id, value) => {
    setDiscountPrices((currDiscountPrices) =>
      currDiscountPrices?.map((discountPrice) => {
        if (discountPrice?.currType === id) {
          discountPrice.amount = value;
        }
        return discountPrice;
      })
    );
  };

  useEffect(() => {
    setDiscountPrices([]);
    currencies?.forEach((currency) => {
      setDiscountPrices((currDiscountPrices) => {
        return [...currDiscountPrices, { currType: currency?._id, amount: 0 }];
      });
    });
  }, [currencies]);

  const addDiscount = async () => {
    try {
      await toast.promise(
        axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/discounts`,
          { ...newDiscountDetails, value: discountPrices },
          {
            headers: { Authorization: token },
          }
        ),
        {
          pending: "Adding Discount...",
          success: "Discount Added Successfully!",
          error: "Failed to Add Discount.",
        }
      );

      fetchAllDiscounts();
      closeModal();
    } catch (error) {
      toast.error("Failed to add Discount");
    }
  };

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="mt-3 ms-2">New Discount</h5>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ overflowX: "auto" }}>
              <table className="table ">
                <tbody>
                  <tr>
                    <td style={{ width: "20%" }}>Name</td>
                    <td>
                      <input
                        type="text"
                        name="title"
                        value={newDiscountDetails?.title || ""}
                        onChange={(e) =>
                          setNewDiscountDetails((curr) => {
                            return { ...curr, title: e.target.value };
                          })
                        }
                        className="form-control mb-2 discount-input"
                        placeholder="Name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "20%" }}>Discount Type</td>
                    <td>
                      <select
                        title="product_cat"
                        name="discountType"
                        className="orderby discount-input"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setNewDiscountDetails((curr) => {
                            return { ...curr, discountType: e.target.value };
                          })
                        }
                      >
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage </option>
                      </select>
                    </td>
                  </tr>
                  {currencies?.map((curr, ind) => (
                    <tr key={curr?._id}>
                      <td>Value ({curr?.symbol})</td>
                      <td>
                        <input
                          type="number"
                          name={"add" + curr?._id}
                          value={discountPrices[ind]?.amount || ""}
                          onChange={(e) => {
                            discountPriceChangeHandler(
                              curr?._id,
                              e.target.value
                            );
                          }}
                          className="form-control mb-2 discount-input"
                          placeholder={curr.name}
                        />
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td style={{ width: "20%" }}>Time Limit</td>
                    <td>
                      <input
                        type="datetime-local"
                        name="symbol"
                        value={newDiscountDetails?.timeLimit || ""}
                        onChange={(e) =>
                          setNewDiscountDetails((curr) => {
                            return { ...curr, timeLimit: e.target.value };
                          })
                        }
                        className="form-control mb-2 discount-input"
                        placeholder="Symbol"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="modal-footer"
              style={{ textTransform: "capitalize" }}
            >
              <button
                className="btn themeBtn"
                onClick={addDiscount}
                disabled={
                  !newDiscountDetails?.title ||
                  !newDiscountDetails?.discountType ||
                  !newDiscountDetails?.timeLimit
                }
              >
                Add Discount
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discounts;
