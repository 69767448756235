import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoutes = () => {
  let { auth, role } = useSelector((state) => state.user);
  return role === "admin" && auth ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default AdminRoutes;
