import React, { useEffect, useState } from "react";
import MyAccountLayout from "../../layouts/MyAccount";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";

const DeletedUsers = () => {
  const { token } = useSelector((state) => state.user);
  const limit = 5;
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    totalFilterResults: 0,
    fetchedUsers: 0,
    page: 1,
    limit: 0,
  });
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });
  const [loadingStatus, setLoadingStatus] = useState(false);

  const fetchPage = async (page) => {
    setLoadingStatus(true);
    let url = `${process.env.REACT_APP_BACKEND_URL}/user/deleted?keyword=${
      searchKeyword || ""
    }&limit=${limit}&page=${page || 1}`;

    try {
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: token } }
      );
      let pagination = {
        totalFilterResults: response?.data?.itemCount,
        page: response?.data?.page,
        limit: response?.data?.limit,
        fetchedUsers: response?.data?.items?.length,
      };
      setPagination(pagination);
      setUsers(response?.data?.items);
      setLoadingStatus(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedUsers + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);
  return (
    <MyAccountLayout>
      <div className="kaycee-MyAccount-content">
        <div className="d-flex align-items-center justify-content-end mb-4">
          <div
            id="kaycee_product_search-2"
            className="widget kaycee widget_product_search"
            onSubmit={(e) => {
              e.preventDefault();
              fetchPage();
            }}
          >
            <form className="kaycee-product-search">
              <input
                id="kaycee-product-search-field-0"
                className="search-field"
                placeholder="Search by Name/Email"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                type="search"
              />
              <button type="submit" value="Search">
                Search
              </button>
            </form>
          </div>
        </div>
        {loadingStatus && (
          <div
            className="d-flex flex-column justify-content-center align-items-center gap-4"
            style={{ height: "40vh" }}
          >
            <img src="/assets/images/Rolling-1s-200px.svg" />
          </div>
        )}
        {!loadingStatus &&
          (!users?.length ? (
            <div className="kaycee-Message kaycee-Message--info kaycee-info text-center">
              No Deleted user accounts available yet.
            </div>
          ) : (
            <table
              className="kaycee-orders-table kaycee-MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table"
              style={{ overflowX: "auto" }}
            >
              <thead>
                <tr>
                  <th className="kaycee-orders-table__header kaycee-orders-table__header-order-number">
                    <span className="nobr">Sr. No.</span>
                  </th>
                  <th className="kaycee-orders-table__header kaycee-orders-table__header-order-date">
                    <span className="nobr">Full Name</span>
                  </th>
                  <th className="kaycee-orders-table__header kaycee-orders-table__header-order-status">
                    <span className="nobr">E-mail</span>
                  </th>
                  <th className="kaycee-orders-table__header kaycee-orders-table__header-order-paymentMethod">
                    <span className="nobr">Mobile No.</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user, index) => {
                  return (
                    <tr key={user?._id}>
                      <td data-title="Sr. No.">{res.from + index}</td>
                      <td
                        data-title="Full Name"
                        style={{ textTransform: "capitalize" }}
                      >
                        {user.fullName}
                      </td>
                      <td data-title="E-mail">{user?.email}</td>
                      <td data-title="Mobile No.">{user?.contact}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ))}
        <Pagination
          totalPages={totalPages}
          activePage={activePage}
          fetchPage={fetchPage}
          res={res}
          totalResults={pagination?.totalFilterResults}
        />
      </div>
    </MyAccountLayout>
  );
};

export default DeletedUsers;
