import React, { useEffect, useState } from "react";
import MyAccountLayout from "../../layouts/MyAccount";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import UserDetailModal from "../../components/Users/UserDetailModal";
import Pagination from "../../components/Pagination";

const Users = () => {
  const { token, isSuperAdmin } = useSelector((state) => state.user);
  const limit = 5;
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    totalFilterResults: 0,
    fetchedUsers: 0,
    page: 1,
    limit: 0,
  });
  const [showUserDetailModal, setShowUserDetailModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });
  const [user, setUser] = useState({});
  const [userCart, setUserCart] = useState([]);
  const [userWishlist, setUserWishlist] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const openModal = () => {
    setShowUserDetailModal(true);
  };
  const closeModal = () => {
    setShowUserDetailModal(false);
  };

  const fetchUser = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/user/${id}`,
      { headers: { Authorization: token } }
    );

    if (response.status === 200) {
      setUser(response?.data?.user);
      setUserCart(response?.data?.cart);
      setUserWishlist(response?.data?.wishlist);
      openModal();
    }
  };

  const fetchPage = async (page) => {
    setLoadingStatus(true);
    let url = `${process.env.REACT_APP_BACKEND_URL}/user?keyword=${
      searchKeyword || ""
    }&limit=${limit}&page=${page || 1}`;

    try {
      const response = await axios.post(
        url,
        {},
        { headers: { Authorization: token } }
      );
      let pagination = {
        totalFilterResults: response?.data?.itemCount,
        page: response?.data?.page,
        limit: response?.data?.limit,
        fetchedUsers: response?.data?.items?.length,
      };
      setPagination(pagination);
      setUsers(response?.data?.items);
      setLoadingStatus(false);
    } catch (error) {
      setLoadingStatus(false);
      toast.error("Failed to fetch users");
    }
  };

  const deleteUser = async (userId) => {
    try {
      await toast.promise(
        axios.delete(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`, {
          headers: {
            Authorization: token,
          },
        }),
        {
          pending: "Deleting User...",
          success: "User Deleted Successfully!",
          error: "Failed to Delete User.",
        }
      );

      fetchPage(activePage);
    } catch (error) {
      toast.error("Failed to Delete User.");
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedUsers + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);

  return (
    <>
      <UserDetailModal
        showModal={showUserDetailModal}
        closeModal={closeModal}
        user={user}
        userCart={userCart}
        userWishlist={userWishlist}
        fetchPage={fetchPage}
        fetchUser={fetchUser}
      />
      <MyAccountLayout>
        <div className="kaycee-MyAccount-content">
          <div className="d-flex align-items-center justify-content-end mb-4">
            <div
              id="kaycee_product_search-2"
              className="widget kaycee widget_product_search"
              onSubmit={(e) => {
                e.preventDefault();
                fetchPage();
              }}
            >
              <form className="kaycee-product-search">
                <input
                  id="kaycee-product-search-field-0"
                  className="search-field"
                  placeholder="Search by Name/Email"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  type="search"
                />
                <button type="submit" value="Search">
                  Search
                </button>
              </form>
            </div>
          </div>
          {loadingStatus && (
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-4"
              style={{ height: "40vh" }}
            >
              <img src="/assets/images/Rolling-1s-200px.svg" />
            </div>
          )}
          {!loadingStatus &&
            (!users?.length ? (
              <div className="kaycee-Message kaycee-Message--info kaycee-info text-center">
                No user available yet.
              </div>
            ) : (
              <table
                className="kaycee-orders-table kaycee-MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table"
                style={{ overflowX: "auto" }}
              >
                <thead>
                  <tr>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-number">
                      <span className="nobr">Sr. No.</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-date">
                      <span className="nobr">Full Name</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-status">
                      <span className="nobr">E-mail</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-paymentMethod">
                      <span className="nobr">Mobile No.</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-total">
                      <span className="nobr">Role</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-actions">
                      <span className="nobr">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user, index) => {
                    return (
                      <tr key={user?._id}>
                        <td data-title="Sr. No.">{res.from + index}</td>
                        <td
                          data-title="Full Name"
                          style={{ textTransform: "capitalize" }}
                        >
                          {user.fullName}
                        </td>
                        <td data-title="E-mail">{user?.email}</td>
                        <td data-title="Mobile No.">{user?.contact}</td>
                        <td
                          data-title="Role"
                          style={{ textTransform: "capitalize" }}
                        >
                          {user?.role}
                        </td>
                        <td
                          data-title="Actions"
                          style={isSuperAdmin ? { minWidth: "170px" } : {}}
                          // className="d-table-cell justify-content-center align-items-center flex-wrap"
                        >
                          <Link
                            className="btn themeBtn"
                            onClick={() => {
                              fetchUser(user?._id);
                            }}
                            style={{ marginRight: "10px" }}
                          >
                            View
                          </Link>
                          {isSuperAdmin && !user.isSuperAdmin && (
                            <Link
                              className="btn themeBtn"
                              onClick={() => {
                                deleteUser(user?._id);
                              }}
                            >
                              Delete
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ))}
          <Pagination
            totalPages={totalPages}
            activePage={activePage}
            fetchPage={fetchPage}
            res={res}
            totalResults={pagination?.totalFilterResults}
          />
        </div>
      </MyAccountLayout>
    </>
  );
};

export default Users;
