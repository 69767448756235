import React from "react";
import HeroSlider from "../components/Home/HeroSlider";
import DynamicBanners from "../components/Home/DynamicBanners";
import TrendSection from "../components/Home/Trend";
import Testimonials from "../components/Home/Testimonials";

const Home = () => {
  return (
    <div className="fullwidth-template">
      <HeroSlider />
      <DynamicBanners />
      <TrendSection />
      <Testimonials />
    </div>
  );
};

export default Home;
