import { useDispatch, useSelector } from "react-redux";
import { fetchProductBySlug, filterProduct } from "../../redux/ProductSlice";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateProductModal from "../../components/Products/Update Product/Modal";
import AddProductModal from "../../components/Products/Add Product/Modal";
import MyAccountLayout from "../../layouts/MyAccount";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import formatPriceWithCommas from "../../components/PriceFormatter";

const Products = () => {
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const limit = 12;
  const dispatch = useDispatch();
  const { data, slugSearch, pagination } = useSelector(
    (state) => state.product
  );
  const [filters, setFilters] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [showUpdateProductModal, setShowUpdateProductModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });
  const [currencies, setCurrencies] = useState([]);

  const openModal = () => {
    setShowUpdateProductModal(true);
  };

  const closeUpdateProductModal = () => {
    setShowUpdateProductModal(false);
  };
  const closeAddProductModal = () => {
    setShowAddProductModal(false);
  };

  const fetchProduct = (slug) => {
    dispatch(fetchProductBySlug(slug));
    openModal();
  };

  const fetchPage = (page) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/product/filter?keyword=${
      searchKeyword || ""
    }&limit=${limit}&page=${page || 1}`;

    dispatch(
      filterProduct({
        url,
        body: {
          // sortBy: "-createdAt",
          selectedCurr: selectedCurrency._id,
        },
      })
    );
  };

  const deleteProduct = async (productId) => {
    try {
      await toast.promise(
        axios.delete(
          `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        ),
        {
          pending: "Deleting Product...",
          success: "Product Deleted Successfully!",
          error: "Failed to Delete Product.",
        }
      );

      fetchPage(activePage);
    } catch (error) {
      toast.error("Failed to Delete Product");
    }
  };

  useEffect(() => {
    fetchPage();

    fetch(`${process.env.REACT_APP_BACKEND_URL}/filter`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setFilters(res);
      })
      .catch((err) => {
        toast.error("Failed to Fetch Product");
      });

    fetch(`${process.env.REACT_APP_BACKEND_URL}/discounts`, {
      headers: { Authorization: token },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setDiscounts(res);
      })
      .catch((err) => {
        toast.error("Failed to Fetch Product");
      });

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/currencies`)
      .then((response) => {
        setCurrencies(response.data);
      })
      .catch(() => {
        toast.error("Failed to fetch currencies");
      });
  }, []);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedProducts + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);

  return (
    <>
      <UpdateProductModal
        showModal={showUpdateProductModal}
        closeModal={closeUpdateProductModal}
        product={slugSearch}
        filters={filters}
        discounts={discounts}
        currencies={currencies}
      />
      <AddProductModal
        showModal={showAddProductModal}
        closeModal={closeAddProductModal}
        filters={filters}
        discounts={discounts}
        reloadPage={() => {
          fetchPage(activePage);
        }}
        currencies={currencies}
      />
      <MyAccountLayout>
        <div className="kaycee-MyAccount-content">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <button
              className="button alt themeBtn my-3 my-sm-0"
              onClick={() => {
                setShowAddProductModal(true);
              }}
            >
              Add Product
            </button>
            <div
              id="kaycee_product_search-2"
              className="widget kaycee widget_product_search"
              onSubmit={(e) => {
                e.preventDefault();
                fetchPage();
              }}
            >
              <form className="kaycee-product-search">
                <input
                  id="kaycee-product-search-field-0"
                  className="search-field"
                  placeholder="Search products…"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  type="search"
                />
                <button type="submit" value="Search">
                  Search
                </button>
              </form>
            </div>
          </div>
          <div className="myaccount-table table-responsive text-center">
            {data?.length <= 0 ? (
              <div className="kaycee-Message kaycee-Message--info kaycee-info">
                No Products available yet.
              </div>
            ) : (
              <table
                className="table table-bordered"
                style={{ overflowX: "auto" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th>Sr. No.</th>
                    <th style={{ minWidth: "150px" }}>Product Name</th>
                    <th style={{ minWidth: "100px" }}>Base Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((product, index) => {
                    let selectedCurrencyBasePrice = product?.basePrice?.find(
                      (p) => p?.currType?._id == selectedCurrency._id
                    )?.amount;
                    return (
                      <tr key={product?._id}>
                        <td>{res.from + index}</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {product?.title}
                        </td>
                        <td>
                          {`${selectedCurrency.symbol} ${formatPriceWithCommas(
                            selectedCurrencyBasePrice
                          )}`}
                        </td>
                        <td
                          style={{ textTransform: "capitalize" }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Link
                            onClick={() => {
                              fetchProduct(product?.slug);
                            }}
                            className="btn themeBtn"
                            style={{ marginRight: "10px" }}
                          >
                            Edit
                          </Link>
                          <Link
                            onClick={() => {
                              deleteProduct(product?._id);
                            }}
                            className="btn themeBtn"
                          >
                            Delete
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <Pagination
            totalPages={totalPages}
            activePage={activePage}
            fetchPage={fetchPage}
            res={res}
            totalResults={pagination.totalFilterResults}
          />
        </div>
      </MyAccountLayout>
    </>
  );
};

export default Products;
