import React, { useEffect, useState } from "react";
import ProductItem from "../components/Shop/ProductItem";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Breadcrumbs from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { filterProduct } from "../redux/ProductSlice";
import axios from "axios";
import { IconContext } from "react-icons/lib/esm";
import { AiOutlineFilter, AiOutlineShop } from "react-icons/ai";
import { PiSortAscending } from "react-icons/pi";
import Pagination from "../components/Pagination";
import FilterModal from "../components/Shop/FilterModal";
import SortModal from "../components/Shop/SortModal";

const Shop = () => {
  const limit = 12;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, pagination } = useSelector((state) => state.product);
  const { selectedCurrency } = useSelector((state) => state.user);
  const [isGridMode, setIsGridMode] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [res, setRes] = useState({
    from: 0,
    to: 0,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [priceRange, setPriceRange] = useState({
    min: 1,
    max: 1000,
  });
  const [priceFilter, setPriceFilter] = useState([
    priceRange.min,
    priceRange.max,
  ]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isHBasePrice, setIsHBasePrice] = useState(false);
  const [searchString, setSearchString] = useState(
    searchParams.get("que") || ""
  );
  const [filters, setFilter] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/filter`)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setFilter(res);
      })
      .catch((err) => {});
  }, []);

  const search = (e) => {
    e.preventDefault();
    navigate({ pathname: "/shop", search: `?que=${searchString}` });
  };

  const fetchPage = (page) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/product/filter?keyword=${
      searchParams.get("que") || ""
    }&limit=${limit}&page=${page || 1}`;

    dispatch(
      filterProduct({
        url,
        body: {
          filterOptions: selectedFilters,
          sortBy,
          basePrice: { gte: priceFilter[0], lte: priceFilter[1] },
          selectedCurr: selectedCurrency._id,
        },
      })
    );
  };

  useEffect(() => {
    if (selectedCurrency?._id) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/product/hBasePrice/${selectedCurrency._id}`
        )
        .then((response) => {
          setPriceRange((range) => {
            return { ...range, max: Math.ceil(response.data) };
          });
          setPriceFilter((filter) => {
            return [priceRange.min, Math.ceil(response.data)];
          });
          setIsHBasePrice(true);
          // fetchPage();
        })
        .catch((error) => {});
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (isHBasePrice) {
      fetchPage();
    }
  }, [selectedFilters, isHBasePrice, sortBy, searchParams.get("que")]);

  useEffect(() => {
    const skip = pagination?.limit * (pagination?.page - 1);
    setRes({
      from: skip + 1,
      to: pagination?.fetchedProducts + skip,
    });

    setTotalPages(
      Math.ceil(pagination?.totalFilterResults / pagination?.limit)
    );
    setActivePage(pagination?.page);
  }, [pagination]);

  const handleViewModeChange = (isGrid) => {
    if (isGrid) {
      setIsGridMode(true);
    } else {
      setIsGridMode(false);
    }
  };

  return (
    <>
      <SortModal
        showModal={showSortModal}
        closeModal={() => {
          setShowSortModal(false);
        }}
        setSortBy={setSortBy}
      />
      <FilterModal
        showModal={showFilterModal}
        closeModal={() => {
          setShowFilterModal(false);
        }}
        val={{ priceRange, selectedFilters, priceFilter }}
        fun={{ setSelectedFilters, setPriceFilter, fetchPage }}
      />
      <Breadcrumbs />
      {/* <h1 className="page-title">Shop</h1> */}
      <div className="main-container shop-page left-sidebar">
        <div className="container">
          <div className="row">
            {!data.length ? (
              <div
                className="d-flex flex-column justify-content-center align-items-center gap-4 col-lg-9 order-1 order-lg-2"
                style={{ height: "80vh" }}
              >
                <IconContext.Provider value={{ color: "#561f7a" }}>
                  <AiOutlineShop size={100} />
                </IconContext.Provider>
                <p>No Product Found. Please Try Again.</p>
              </div>
            ) : (
              <div className="main-content col-xl-9 col-lg-8 col-md-8 col-sm-12 has-sidebar">
                <div className="shop-control shop-before-control">
                  <div className="grid-view-mode">
                    <form>
                      <Link
                        data-toggle="tooltip"
                        data-placement="top"
                        className={`modes-mode mode-grid display-mode ${
                          isGridMode ? "active" : ""
                        }`}
                        value="grid"
                        onClick={() => handleViewModeChange(true)}
                      >
                        <span className="button-inner">
                          Shop Grid
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </Link>
                      <Link
                        data-toggle="tooltip"
                        data-placement="top"
                        className={`modes-mode mode-list display-mode ${
                          isGridMode ? "" : " active"
                        }`}
                        value="list"
                        onClick={() => handleViewModeChange(false)}
                      >
                        <span className="button-inner">
                          Shop List
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </Link>
                    </form>
                  </div>
                  <form className="kaycee-ordering">
                    <select
                      title="product_cat"
                      name="orderby"
                      className="orderby"
                      defaultValue={""}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="">Default sorting</option>
                      <option value="title">Title (A - Z)</option>
                      <option value="-title">Title (Z - A)</option>
                      <option value="basePrice">Price (Low - High)</option>
                      <option value="-basePrice">Price (High - Low)</option>
                    </select>
                  </form>
                  {/* <form className="per-page-form">
                    <label>
                      <select className="option-perpage">
                        <option value="12" selected="">
                          Show 12
                        </option>
                        <option value="5">Show 05</option>
                        <option value="10">Show 10</option>
                        <option value="12">Show 12</option>
                        <option value="15">Show 15</option>
                        <option value="20">Show All</option>
                      </select>
                    </label>
                  </form> */}
                </div>
                <div className="auto-clear kaycee-products">
                  <ul
                    className="row products columns-3 justify-content-center justify-content-sm-start"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    {data.map((product, index) => (
                      <ProductItem
                        key={index}
                        {...product}
                        isGridMode={isGridMode}
                        // currencies={currencies}
                      />
                    ))}
                  </ul>
                </div>
                <Pagination
                  totalPages={totalPages}
                  activePage={activePage}
                  fetchPage={fetchPage}
                  res={res}
                  totalResults={pagination.totalFilterResults}
                />
              </div>
            )}
            <div className="sidebar col-xl-3 col-lg-4 col-md-4 col-sm-12 d-none d-md-block px-4">
              <div id="widget-area" className="widget-area shop-sidebar">
                <div
                  id="kaycee_product_search-2"
                  className="widget kaycee widget_product_search"
                >
                  <form className="kaycee-product-search" onSubmit={search}>
                    <input
                      id="kaycee-product-search-field-0"
                      className="search-field"
                      placeholder="Search products…"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      type="search"
                    />
                    <button type="submit" value="Search">
                      Search
                    </button>
                  </form>
                </div>
                <PriceFilterComponent
                  priceRange={priceRange}
                  values={priceFilter}
                  setValues={setPriceFilter}
                  fetchPage={fetchPage}
                  selectedCurrency={selectedCurrency}
                />
                {/* <FilterList filterName="Size" filterOptions={sizes} />
                <FilterList filterName="Color" filterOptions={colors} /> */}
                {filters?.map((filter) => (
                  <FilterList
                    key={filter?._id}
                    filterName={filter?.name}
                    filterOptions={filter.filterOptions}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                  />
                ))}
              </div>
            </div>
            <div
              className="d-md-none"
              style={{
                position: "fixed",
                bottom: "50px",
                background: "white",
                padding: "7px",
                zIndex: 99,
                left: 0,
                width: "100%",
                borderTop: "1px solid #e1e1e1",
              }}
            >
              <button
                className="col-6 btn themeBtn "
                style={{
                  borderRight: "1px solid #e1e1e1",
                  background: "none",
                  color: "black",
                  fontSize: "14px",
                }}
                onClick={() => {
                  setShowSortModal(true);
                }}
              >
                <PiSortAscending size={22} />
                Sort
              </button>
              <button
                className="col-6 btn themeBtn"
                onClick={() => {
                  setShowFilterModal(true);
                }}
                style={{ background: "none", color: "black", fontSize: "14px" }}
              >
                <AiOutlineFilter size={22} />
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PriceFilterComponent = ({
  priceRange,
  values,
  setValues,
  fetchPage,
  selectedCurrency,
}) => {
  const handleChange = (newValues) => {
    setValues(newValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchPage();
  };

  return (
    <div className="widget kaycee widget_price_filter">
      <h2 className="widgettitle">
        Filter By Price<span className="arrow"></span>
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="price_slider_wrapper">
          <Slider
            min={priceRange.min}
            max={priceRange.max}
            range
            defaultValue={[priceRange.min, priceRange.max]}
            value={values}
            onChange={handleChange}
          />
          <div className="price_slider_amount">
            <button type="submit" className="button">
              Filter
            </button>
            <div className="price_label">
              Price:{" "}
              <span className="from">
                {selectedCurrency.symbol} {values[0]}
              </span>
              —{" "}
              <span className="to">
                {selectedCurrency.symbol} {values[1]}
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FilterList = ({
  filterName,
  filterOptions,
  selectedFilters,
  setSelectedFilters,
}) => {
  const handleCheckboxChange = (e, filterId) => {
    const isChecked = e.target.checked;

    setSelectedFilters((prevFilters) => {
      if (isChecked) {
        return [...prevFilters, filterId];
      } else {
        return prevFilters.filter((id) => id !== filterId);
      }
    });
  };
  return (
    <div
      id="kaycee_layered_nav-6"
      className="widget kaycee widget_layered_nav kaycee-widget-layered-nav"
    >
      <h2 className="widgettitle">
        Filter By {filterName}
        <span className="arrow"></span>
      </h2>
      <ul className="kaycee-widget-layered-nav-list">
        {filterOptions?.map((option, index) => (
          <li
            key={option?._id}
            className="kaycee-widget-layered-nav-list__item kaycee-layered-nav-term"
          >
            <label>
              <input
                type="checkbox"
                name={filterName}
                value={option?._id}
                onChange={(e) => handleCheckboxChange(e, option?._id)}
                checked={selectedFilters?.includes(option?._id)}
                id={`customCheck-${filterName?.split(" ")[0]}-${index}`}
              />
              <span>{option.name}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Shop;
