import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MobileFooter from "./components/MobileFooter";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";
import Shop from "./pages/Shop";
import Cart from "./pages/Cart";
import CheckoutForm from "./pages/Checkout";
import Wishlist from "./pages/Wishlist";
import ProductDetails from "./pages/ProductDetails";
import Dashboard from "./pages/Account/Dashboard";
import Orders from "./pages/Account/Orders";
import Addresses from "./pages/Account/Addresses";
import Downloads from "./pages/Account/Downloads";
import AccountDetails from "./pages/Account/AccountDetails";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRoutes from "./Routes/AdminRoutes";
import CustomerRoutes from "./Routes/CustomerRoutes";
import Users from "./pages/Account/Users";
import DeletedUsers from "./pages/Account/DeletedUsers";
import Products from "./pages/Account/Products";
import Filters from "./pages/Account/Filters";
import Currencies from "./pages/Account/Currencies";
import Discounts from "./pages/Account/Discounts";

function App() {
  return (
    <>
      <Header />
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/:slug" element={<ProductDetails />} />
        <Route element={<AdminRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/orders" element={<Orders />} />
          {/* <Route path="/downloads" element={<Downloads />} /> */}
          <Route path="/products" element={<Products />} />
          <Route path="/filters" element={<Filters />} />
          <Route path="/discounts" element={<Discounts />} />
          <Route path="/currencies" element={<Currencies />} />
          <Route path="/users" element={<Users />} />
          <Route path="/deleted-users" element={<DeletedUsers />} />
          {/* <Route path="/account-details" element={<AccountDetails />} /> */}
        </Route>
        <Route element={<CustomerRoutes />}>
          {/* <Route path="/my-account/dashboard" element={<Dashboard />} /> */}
          <Route path="/my-account/orders" element={<Orders />} />
          {/* <Route path="/my-account/downloads" element={<Downloads />} /> */}
          <Route path="/my-account/addresses" element={<Addresses />} />
          {/* <Route  
            path="/my-account/account-details"
            element={<AccountDetails />}
          /> */}
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
      <MobileFooter />
    </>
  );
}

export default App;
