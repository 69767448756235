import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Slider from "react-slick";

const TeamMember = ({ name, position, imgSrc }) => {
  return (
    <div className="kaycee-team style-01" style={{ margin: "20px" }}>
      <div className="team-inner">
        <div className="thumb-avatar">
          <a href="#" target="_self" tabIndex="0">
            <img
              src={imgSrc}
              className="attachment-full size-full"
              alt={name}
            />
          </a>
          <div className="list-social">
            <a href="#" tabIndex="0">
              <i className="az_tta-icon fa fa-facebook"></i>
            </a>
            <a href="#" tabIndex="0">
              <i className="az_tta-icon fa fa-twitter"></i>
            </a>
            <a href="#" tabIndex="0">
              <i className="az_tta-icon fa fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="content-team">
          <h3 className="name">
            <a href="#" target="_self" tabIndex="0">
              {name}
            </a>
          </h3>
          <p className="positions">{position}</p>
        </div>
      </div>
    </div>
  );
};

const BrandLogo = ({ imgSrc }) => {
  return (
    <div
      className="dreaming_single_image dreaming_content_element az_align_center"
      style={{ margin: "0 20px" }}
    >
      <figure className="dreaming_wrapper az_figure">
        <div className="az_single_image-wrapper az_box_border_grey effect bounce-in">
          <img
            src={imgSrc}
            className="az_single_image-img attachment-full"
            alt="Brand Logo"
            width="200"
            height="100"
          />
        </div>
      </figure>
    </div>
  );
};

const About = () => {
  const teamMembers = [
    {
      name: "Annie Taylor",
      position: "Operations",
      imgSrc: "/assets/images/team-img1.jpg",
    },
    {
      name: "Ayomide Regan",
      position: "Marketing Personal",
      imgSrc: "/assets/images/team-img2.jpg",
    },
    {
      name: "Violet Frase",
      position: "Director",
      imgSrc: "/assets/images/team-img3.jpg",
    },
    {
      name: "Frank Greer",
      position: "Operations",
      imgSrc: "/assets/images/team-img4.jpg",
    },
    {
      name: "Mark Tucker",
      position: "Partner",
      imgSrc: "/assets/images/team5-1.jpg",
    },
    {
      name: "Perry Conner",
      position: "Partner",
      imgSrc: "/assets/images/team6.jpg",
    },
  ];

  // Brand Logos Data
  const brandLogos = [
    "/assets/images/brand-logo-1.png",
    "/assets/images/brand-logo-2.png",
    "/assets/images/brand-logo-3.png",
    "/assets/images/brand-logo-4.png",
    "/assets/images/brand-logo-5.png",
  ];

  const teamSliderSettings = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    slidesMargin: 30,
    dots: true,
    infinite: false,
    speed: 300,
    autoplay: true,
    rows: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesMargin: 10,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesMargin: 10,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesMargin: 20,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesMargin: 20,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesMargin: 30,
        },
      },
    ],
  };

  const brandSliderSettings = {
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    slidesMargin: 60,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    rows: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesMargin: 30,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesMargin: 30,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesMargin: 40,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesMargin: 50,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesMargin: 60,
        },
      },
    ],
  };

  return (
    <>
      <Breadcrumb />
      <div className="site-main main-container no-sidebar">
        <div className="section-037">
          <div className="container">
            <div className="kaycee-popupvideo style-01">
              <div className="popupvideo-inner">
                <div className="icon">
                  <img
                    src="/assets/images/about-img.jpg"
                    className="attachment-full size-full"
                    alt="img"
                  />
                  <div className="product-video-button">
                    <a
                      className="buttonvideo"
                      href="#"
                      data-videosite="vimeo"
                      data-videoid="28605017"
                      tabIndex="0"
                    >
                      <div className="videobox_animation circle_1"></div>
                      <div className="videobox_animation circle_2"></div>
                      <div className="videobox_animation circle_3"></div>
                    </a>
                  </div>
                </div>
                <div className="popupvideo-wrap">
                  <h4 className="title">Who we are </h4>
                  <p className="desc">
                    We believe in a world where you have total freedom to be
                    you, without judgement. To experiment. To express yourself.
                    To be brave and grab life as the extraordinary adventure it
                    is. So we make sure everyone has an equal chance to discover
                    all the amazing things they’re capable of – no matter who
                    they are, where they’re from or what looks they like to
                    boss.
                  </p>
                  <p>
                    Our audience (AKA you) is wonderfully unique. And we do
                    everything we can to help you find your fit, offering our
                    Ciloe Brands in more than 30 sizes – and we’re committed to
                    providing all sizes at the same price
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-001">
          <div className="container">
            <div className="kaycee-heading style-01">
              <div className="heading-inner">
                <h3 className="title">
                  Meet Our Team <span></span>
                </h3>
                <div className="subtitle">
                  A perfect blend of creativity and technical
                </div>
              </div>
            </div>
            <div className="kaycee-slide">
              <Slider {...teamSliderSettings}>
                {teamMembers.map((member, index) => (
                  <TeamMember key={index} {...member} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="section-039 section-001">
          <div className="container">
            <div className="kaycee-slide">
              <Slider {...brandSliderSettings}>
                {brandLogos.map((logo, index) => (
                  <BrandLogo key={index} imgSrc={logo} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
