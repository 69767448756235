import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumbs from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToWishlist } from "../redux/WishlistSlice";
import { addToCart } from "../redux/CartSlice";
import pathToUrl from "../components/PathToUrl";
import { Link, useParams } from "react-router-dom";
import { fetchProductBySlug } from "../redux/ProductSlice";
import ErrorPage from "./ErrorPage";
import formatPriceWithCommas from "../components/PriceFormatter";

const ProductDetails = () => {
  const mainSlideRef = useRef(null);
  const dotSlideRef = useRef(null);
  const dispatch = useDispatch();
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const { slugSearch: product, status } = useSelector((state) => state.product);
  const { slug } = useParams();
  // const { imageUrls } = product;
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [discountAmount, setDiscountsAmount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchProductBySlug(slug));
  }, [slug]);

  useEffect(() => {
    const initialOptions = [];

    product?.variants?.map(
      (variant, index) =>
        (initialOptions[index] = {
          variantType: variant?._id,
          selectedOption: variant?.options && variant?.options[0]._id,
          incRate: variant?.options && variant?.options[0].incRate,
        })
    );
    setSelectedOptions(initialOptions);
  }, [product]);

  useEffect(() => {
    const calculatedPrice =
      product?.basePrice?.find((p) => p?.currType == selectedCurrency?._id)
        ?.amount +
      selectedOptions.reduce((acc, option) => {
        return (
          acc +
            option.incRate.find((p) => p?.currType == selectedCurrency?._id)
              ?.amount || 0
        );
      }, 0);
    let currTime = new Date().getTime();
    let calculatedDiscount = product?.discounts?.reduce((total, discount) => {
      if (currTime >= new Date(discount.timeLimit).getTime()) return total;
      let discountValue =
        discount.value.find(
          (val) => val?.currType?._id === selectedCurrency._id
        )?.amount || 0;

      if (discountValue <= 0) return total;

      if (discount.discountType === "percentage") {
        return total + (calculatedPrice * discountValue) / 100;
      } else {
        return total + discountValue;
      }
    }, 0);

    setPrice(calculatedPrice);
    setDiscountsAmount(calculatedDiscount);
  }, [selectedOptions, product, selectedCurrency, slug]);

  const handleOptionChange = (variantIndex, optionIndex) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[variantIndex] = {
      variantType: product?.variants[variantIndex]?._id,
      selectedOption:
        product?.variants[variantIndex]?.options[optionIndex]?._id,
      incRate: product?.variants[variantIndex]?.options[optionIndex]?.incRate,
    };
    setSelectedOptions(updatedOptions);
  };

  const addItem = async () => {
    if (!token) return toast.error("Login to add item in wishlist");
    try {
      await toast.promise(
        dispatch(addToWishlist({ token, id: product?._id })),
        {
          pending: "Adding to Wishlist...",
          success: "Item added to Wishlist!",
          error: "Failed to add item to Wishlist.",
        }
      );
    } catch (error) {
      toast.error("Failed to add item to Wishlist");
    }
  };

  const addToCartHandler = async (e) => {
    e.preventDefault();
    // let options = selectedOptions.map((option) => {
    //   let { incRate, ...rest } = option;
    //   return rest;
    // });
    // return console.log(options);
    if (!(price - discountAmount)) return toast.error("Item not available");
    if (!token) return toast.error("Login to add item in cart");

    const itemDetail = {
      productId: product._id,
      options: selectedOptions,
      quantity,
    };

    try {
      await toast.promise(dispatch(addToCart({ token, itemDetail })), {
        pending: "Adding to Cart...",
        success: "Item added to Cart!",
        error: "Failed to add item to Cart.",
      });
    } catch (error) {
      toast.error("Failed to add item to Cart.");
    }
  };

  // const shareOnFacebook = () => {
  //   window.FB.ui({
  //     method: "share",
  //     href: window.location.href,
  //   });
  // };

  // const shareOnWhatsApp = () => {
  //   window.open(
  //     `https://api.whatsapp.com/send?text=${encodeURIComponent(
  //       window.location.href
  //     )}`
  //   );
  // };

  // const shareOnTwitter = () => {
  //   window.open(
  //     `https://twitter.com/intent/tweet?text=${encodeURIComponent(
  //       "Check out this link: " + window.location.href
  //     )}`
  //   );
  // };

  // const shareOnPinterest = () => {
  //   const mediaUrl = encodeURIComponent(
  //     pathToUrl(imageUrls?.length && imageUrls[0])
  //   );

  //   window.open(
  //     `https://www.pinterest.com/pin/create/button?media=${mediaUrl}`,
  //     "_blank"
  //   );
  // };

  const [activeTab, setActiveTab] = useState("additional_information");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    asnavfor: dotSlideRef?.current,
    autoplay: true,
    focusOnSelect: true,
    arrows: false,
  };

  const settingsDot = {
    vertical: true,
    slidesToShow:
      product?.imageUrls?.length > 3 ? 3 : product?.imageUrls?.length,
    slidesToScroll: 1,
    asnavfor: mainSlideRef?.current,
    arrows: false,
    focusOnSelect: true,
    autoplay: true,
  };

  return product.error ? (
    <ErrorPage />
  ) : (
    <>
      <Breadcrumbs />
      {status === "loading" && (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4"
          style={{ height: "80vh" }}
        >
          <img src="/assets/images/Rolling-1s-200px.svg" />
        </div>
      )}
      {status === "succeeded" && (
        <div className="single-thumb-vertical main-container shop-page no-sidebar">
          <div className="container">
            <div className="row">
              <div className="main-content col-md-12">
                <div className="kaycee-notices-wrapper"></div>
                <div className="post-27 product type-product status-publish has-post-thumbnail product_cat-table product_cat-new-arrivals product_cat-lamp product_tag-table product_tag-sock first instock shipping-taxable purchasable product-type-variable has-default-attributes">
                  <div className="main-contain-summary">
                    <div className="contain-left has-gallery">
                      <div className="single-left">
                        <div className="kaycee-product-gallery kaycee-product-gallery--with-images kaycee-product-gallery--columns-4 images">
                          <Link
                            className="kaycee-product-gallery__trigger"
                            style={{ textAlign: "center" }}
                            onClick={addItem}
                          ></Link>
                          <div className="flex-viewport">
                            <Slider
                              ref={mainSlideRef}
                              {...settingsMain}
                              className="kaycee-product-gallery__wrapper"
                            >
                              {product?.imageUrls?.map((imageUrl, index) => (
                                <div
                                  className="kaycee-product-gallery__image d-flex align-items-center justify-content-center"
                                  key={imageUrl}
                                  style={{
                                    height: "90%",
                                    width: "90%",
                                  }}
                                >
                                  <img
                                    alt={`img-${index}`}
                                    src={pathToUrl(imageUrl)}
                                    style={{ maxHeight: "75vh" }}
                                  />
                                </div>
                              ))}
                            </Slider>
                          </div>
                          <Slider
                            {...settingsDot}
                            asNavFor={mainSlideRef.current}
                            ref={dotSlideRef}
                            style={{ width: "125px" }}
                            className="d-none d-lg-block"
                          >
                            {product?.imageUrls?.map((imageUrl, index) => (
                              <div
                                key={imageUrl}
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: "100%" }}
                              >
                                <img
                                  alt={`img-thumb-${index}`}
                                  src={pathToUrl(imageUrl)}
                                  style={{ height: "80px", width: "100%" }}
                                />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                      <div className="summary entry-summary">
                        <div className="flash">
                          <span className="onnew">
                            <span className="text">New</span>
                          </span>
                        </div>
                        <h1 className="product_title entry-title">
                          {product?.title}
                        </h1>
                        <p className="price">
                          {(discountAmount && (
                            <del className="kaycee-Price-amount amount mr-2">
                              <span className="kaycee-Price-currencySymbol">
                                {selectedCurrency.symbol}
                              </span>
                              {formatPriceWithCommas(price || 0)}
                            </del>
                          )) || <></>}
                          <span className="kaycee-Price-amount amount">
                            <span className="kaycee-Price-currencySymbol">
                              {(price - discountAmount &&
                                selectedCurrency.symbol) || <></>}{" "}
                            </span>
                            {formatPriceWithCommas(
                              (price - discountAmount).toFixed(2) || 0
                            ) || "Not Available"}
                          </span>
                        </p>
                        <div className="kaycee-product-details__short-description">
                          <p>{product.description}</p>
                          <ul>
                            {product?.features?.map((feature, index) => (
                              <li key={index}>{feature}</li>
                            ))}
                          </ul>
                        </div>
                        <form className="variations_form cart">
                          <div className="single_variation_wrap">
                            <div className="kaycee-variation single_variation"></div>
                            <div className="kaycee-variation-add-to-cart variations_button">
                              <div className="quantity">
                                <span className="qty-label">Quantiy:</span>
                                <div className="control">
                                  <a
                                    className="btn-number qtyminus quantity-minus"
                                    href="#"
                                    onClick={decreaseQuantity}
                                  >
                                    -
                                  </a>
                                  <input
                                    type="number"
                                    name="quantity[25]"
                                    value={quantity}
                                    // title="Qty"
                                    className="input-qty input-text text"
                                    readOnly
                                  />
                                  <a
                                    className="btn-number qtyplus quantity-plus"
                                    href="#"
                                    onClick={increaseQuantity}
                                  >
                                    +
                                  </a>
                                </div>
                              </div>
                              <button
                                className="single_add_to_cart_button button alt kaycee-variation-selection-needed"
                                onClick={addToCartHandler}
                                disabled={!(price - discountAmount)}
                              >
                                Add to cart
                              </button>
                              <div className="d-flex d-md-block flex-wrap justify-content-around flex-wrap">
                                {product?.variants?.map(
                                  (variant, variantIndex) => (
                                    <div className="pro-size" key={variant._id}>
                                      <h6 className="option-title">
                                        {variant.type}:
                                      </h6>
                                      <select
                                        onChange={(e) =>
                                          handleOptionChange(
                                            variantIndex,
                                            e.target.selectedIndex
                                          )
                                        }
                                        className="nice-select"
                                      >
                                        {variant?.options?.map((option) => (
                                          <option
                                            key={option?._id}
                                            value={option?.incRate}
                                            disabled={
                                              !option?.inStock ||
                                              !option.isActive
                                            }
                                          >
                                            {option?.name}
                                          </option>
                                        ))}
                                      </select>
                                      <br />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="product_meta mt-4 ml-2">
                          {/* <span className="sku_wrapper">
                          SKU: <span className="sku">{product.sku}</span>
                        </span>
                        <span className="posted_in">
                          Categories:{" "}
                          <span style={{ color: "black" }}>
                            {product?.categories?.join(", ")}
                          </span>
                        </span> */}
                          <span className="tagged_as">
                            Tags:{" "}
                            <span style={{ color: "black" }}>
                              {product?.tags?.join(", ")}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="py-5">
                    <ul className="tabs dreaming-tabs">
                      {product?.variants?.length ? (
                        <li
                          className={`additional_information_tab px-0 ${
                            activeTab === "additional_information"
                              ? "active"
                              : ""
                          }`}
                          id="tab-title-additional_information"
                        >
                          <a
                            href="#"
                            onClick={() =>
                              handleTabClick("additional_information")
                            }
                          >
                            Additional information
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>

                    <div
                      className={`kaycee-Tabs-panel kaycee-Tabs-panel--additional_information panel entry-content kaycee-tab ${
                        activeTab === "additional_information"
                          ? "active"
                          : "d-none"
                      }`}
                    >
                      <h2>Additional information</h2>
                      <table className="shop_attributes">
                        <tbody>
                          {product?.variants?.map((variant, index) => {
                            return (
                              <tr key={index}>
                                <td>{variant.type}</td>
                                <td>
                                  {variant?.options?.map(
                                    (option, index) =>
                                      `${option?.name}${
                                        index != variant?.options.length - 1
                                          ? ", "
                                          : ""
                                      }`
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
