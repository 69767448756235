import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const filterProduct = createAsyncThunk(
  "products/filterProduct",
  async ({ url, body }, thunkAPI) => {
    try {
      const response = await axios.post(url, body);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const specialProduct = createAsyncThunk(
  "products/specialProduct",
  async (type, thunkAPI) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/product/${type}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchProductBySlug = createAsyncThunk(
  "product/fetchProductBySlug",
  async (slug) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/product/slugSearch/${slug}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
    featured: [],
    bestSeller: [],
    onSale: [],
    status: "idle",
    error: null,
    slugSearch: {},
    pagination: {
      totalFilterResults: 0,
      fetchedProducts: 0,
      page: 1,
      limit: 0,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(filterProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(filterProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload?.items;
        let pagination = {
          totalFilterResults: action.payload.itemCount,
          page: action.payload.page,
          limit: action.payload.limit,
          fetchedProducts: action.payload?.items?.length,
        };
        state.pagination = pagination;
      })
      .addCase(filterProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(specialProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(specialProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.type === "featured") {
          state.featured = action.payload.products;
        } else if (action.payload.type === "onSale") {
          state.onSale = action.payload.products;
        } else if (action.payload.type === "bestSeller") {
          state.bestSeller = action.payload.products;
        }
      })
      .addCase(specialProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchProductBySlug.pending, (state) => {
        state.status = "loading";
        state.slugSearch = {};
      })
      .addCase(fetchProductBySlug.fulfilled, (state, action) => {
        state.slugSearch = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchProductBySlug.rejected, (state, action) => {
        state.status = "failed";
        state.slugSearch = {};
        state.error = action.error.message;
      });
  },
});

export default productSlice.reducer;
