import Slider from "react-slick";

const testimonials = [
  {
    id: 1,
    avatar: "/assets/images/avatar1.png",
    description:
      "Lorem ipsum dolor sit amet consectetur elit sagittis, quisque ut integer penatibus eleifend erat porttitor, viverra tristique dapibus fermentum montes aptent enim magnis nec a neque.",
    name: "Charley Pratt",
    position: "Customer",
  },
  {
    id: 2,
    avatar: "/assets/images/avatar3.png",
    description:
      "Lorem ipsum dolor sit amet consectetur elit sagittis, quisque ut integer penatibus eleifend erat porttitor, viverra tristique dapibus fermentum montes aptent enim magnis nec a neque.",
    name: "Nadia Pugh",
    position: "Customer",
  },
  {
    id: 3,
    avatar: "/assets/images/avatar2.png",
    description:
      "Lorem ipsum dolor sit amet consectetur elit sagittis, quisque ut integer penatibus eleifend erat porttitor, viverra tristique dapibus fermentum montes aptent enim magnis nec a neque.",
    name: "Troy Bryant",
    position: "Customer",
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="section-0181">
        <div className="container">
          <div className="kaycee-heading style-01">
            <div className="heading-inner">
              <h3 className="title">
                What's Clients Say? <span></span>
              </h3>
              <div className="subtitle">
                Lorem ipsum dolor sit amet consectetur
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-018">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="kaycee-slide">
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div className="kaycee-testimonial style-01" key={index}>
                      <div className="testimonial-inner">
                        <div className="thumb">
                          <img
                            src={testimonial.avatar}
                            className="attachment-full size-full"
                            alt="img"
                            width="97"
                            height="97"
                          />
                        </div>
                        <p className="desc">{testimonial.description}</p>
                        <div className="testimonial-info">
                          <div className="intro">
                            <h3 className="name">
                              <a href="#" target="_self" tabIndex="0">
                                {testimonial.name}
                              </a>
                            </h3>
                            <div className="position">
                              {testimonial.position}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
