import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="main-container text-center error-404 not-found">
      <div className="container">
        <h1 className="title-404">404</h1>
        <h1 className="title">Opps! This page Could Not Be Found!</h1>
        <p className="subtitle">
          Sorry bit the page you are looking for does not exist, have been
          removed or name changed
        </p>
        <Link to="/" className="button">
          Back to hompage
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
