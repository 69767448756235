import React from "react";

const LoginRegisterLayout = ({ children }) => {
  return (
    <main className="site-main main-container no-sidebar">
      <div className="container">
        <div className="row">
          <div className="main-content col-md-12">
            <div className="page-main-content">
              <div className="kaycee">
                <div className="kaycee-notices-wrapper"></div>
                <div className="u-columns col2-set " id="customer_login">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginRegisterLayout;
