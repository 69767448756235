import React from "react";

const bannersData = [
  {
    imageSrc: "/assets/images/banner31.jpg",
    style: "style-08 left-center",
    title: "Best Seller",
    description: "Check out our your\njewelry collection now!",
    buttonLabel: "Shop now",
  },
  {
    imageSrc: "/assets/images/banner32.jpg",
    style: "style-09 left-center",
    label: "End this weekend",
    title: "Big Sale\n75% Off",
    promoCode: "BRANDON",
  },
  {
    imageSrc: "/assets/images/banner33.jpg",
    style: "style-10 left-center",
    title: "Lookbook",
    description: "New Jewelry Collections\nSummer Lookbook",
    buttonLabel: "Shop now",
  },
];

const DynamicBanners = () => {
  return (
    <div className="section-017">
      <div className="row">
        {bannersData.map((banner, index) => (
          <div key={index} className="col-md-12 col-lg-4">
            <div className={`kaycee-banner ${banner.style}`}>
              <div className="banner-inner">
                <figure className="banner-thumb">
                  <img
                    src={banner.imageSrc}
                    className="attachment-full size-full"
                    alt="img"
                  />
                </figure>
                <div className="banner-info">
                  <div className="banner-content">
                    <div className="title-wrap">
                      <h6 className="title">{banner.title}</h6>
                      {banner.label && (
                        <div className="banner-label">{banner.label}</div>
                      )}
                    </div>
                    <div className="cate">
                      {banner.description &&
                        banner.description.split("\n").map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </div>
                    {banner.promoCode && (
                      <div className="cate">
                        Promo Code: <strong>{banner.promoCode}</strong>
                      </div>
                    )}
                    {banner.buttonLabel && (
                      <div className="button-wrap">
                        <a className="button" target="_self" href="#">
                          <span>{banner.buttonLabel}</span>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DynamicBanners;
