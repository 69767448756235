import React, { useEffect } from "react";
import Breadcrumbs from "../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { fetchWishlistItems, removeFromWishlist } from "../redux/WishlistSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import pathToUrl from "../components/PathToUrl";
import { IconContext } from "react-icons";
import { BsFillBagHeartFill } from "react-icons/bs";
import formatPriceWithCommas from "../components/PriceFormatter";

const Wishlist = () => {
  const dispatch = useDispatch();
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const { items: wishlistItems } = useSelector((state) => state.wishlist);

  useEffect(() => {
    dispatch(fetchWishlistItems(token));
  }, [dispatch, token]);

  const handleRemove = async (itemId) => {
    try {
      await toast.promise(dispatch(removeFromWishlist({ token, id: itemId })), {
        pending: "Removing item...",
        success: "Item removed successfully!",
        error: "Failed to remove item.",
      });
    } catch (error) {
      toast.error("Failed removing item. Please try again.");
    }
  };

  return (
    <>
      <Breadcrumbs />
      {wishlistItems?.length > 0 ? (
        <main className="site-main main-container no-sidebar">
          <div className="container">
            <div className="row">
              <div className="main-content col-md-12">
                <div className="page-main-content">
                  <form id="yith-wcwl-form" className="kaycee">
                    <table
                      className="shop_table cart wishlist_table"
                      data-pagination="no"
                      data-per-page="5"
                      data-page="1"
                      data-id=""
                      data-token=""
                    >
                      <thead>
                        <tr>
                          <th className="product-remove"></th>
                          <th className="product-thumbnail"></th>
                          <th className="product-name">
                            <span className="nobr">Product Name</span>
                          </th>
                          <th className="product-price">
                            <span className="nobr">Unit Price</span>
                          </th>
                          <th className="product-stock-status">
                            <span className="nobr">Stock Status</span>
                          </th>
                          <th className="product-add-to-cart"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {wishlistItems.map((item) => {
                          let price = item?.basePrice?.find(
                            (p) => p.currType === selectedCurrency._id
                          )?.amount ? (
                            <span className="kaycee-Price-amount amount">
                              <span className="kaycee-Price-currencySymbol">
                                {selectedCurrency.symbol}
                              </span>{" "}
                              {formatPriceWithCommas(
                                item?.basePrice
                                  ?.find(
                                    (p) => p.currType === selectedCurrency._id
                                  )
                                  ?.amount?.toFixed(2)
                              )}
                            </span>
                          ) : (
                            <>Not Available</>
                          );
                          return (
                            <>
                              <tr
                                key={item._id}
                                id={`yith-wcwl-row-${item._id}`}
                                data-row-id={item._id}
                              >
                                <td className="product-remove">
                                  <div>
                                    <Link
                                      className="remove remove_from_wishlist"
                                      title="Remove this product"
                                      onClick={() => handleRemove(item._id)}
                                    >
                                      x
                                    </Link>
                                  </div>
                                </td>
                                <td
                                  className="product-thumbnail"
                                  style={{ width: "30%" }}
                                >
                                  <Link to={`/${item?.slug}`}>
                                    <img
                                      src={
                                        item.imageUrls &&
                                        pathToUrl(item?.imageUrls[0])
                                      }
                                      alt={item.title}
                                      className="attachment-kaycee_thumbnail size-kaycee_thumbnail"
                                    />
                                  </Link>
                                </td>
                                <td
                                  className="product-name"
                                  style={{ width: "30%" }}
                                >
                                  <Link to={`/${item.slug}`}>{item.title}</Link>
                                  <Link
                                    to={`/${item.slug}`}
                                    className="button yith-wcqv-button"
                                    data-product_id={item.id}
                                  >
                                    Quick View
                                  </Link>
                                </td>
                                <td
                                  className="product-price d-none d-sm-table-cell"
                                  style={{ width: "30%" }}
                                >
                                  {price}
                                </td>
                              </tr>
                              <tr className="d-sm-none">
                                <td colSpan="6">{price}</td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="6"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-4 px-5 text-center"
          style={{ height: "80vh" }}
        >
          <IconContext.Provider value={{ color: "#561f7a" }}>
            <BsFillBagHeartFill size={100} />
          </IconContext.Provider>
          <p>
            Nothing in Wishlist. Please select from the{" "}
            <Link to="/shop" style={{ color: "#561f7a" }}>
              Shop
            </Link>
            .
          </p>
        </div>
      )}
    </>
  );
};

export default Wishlist;
