import ProductDetailsTabs from "./DetailTabs";
import { ToastContainer } from "react-toastify";

const AddProductModal = ({
  showModal,
  closeModal,
  filters,
  discounts,
  reloadPage,
  currencies,
}) => {
  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="addProductModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <>
                  <h5 className="modal-title">Add New Product</h5>
                </>
              </div>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <ProductDetailsTabs
              filters={filters}
              discounts={discounts}
              reloadPage={reloadPage}
              closeModal={closeModal}
              currencies={currencies}
              showModal={showModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProductModal;
