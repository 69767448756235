import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { changeCurrency, logout } from "../redux/UserSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { fetchCartItems, removeFromCart } from "../redux/CartSlice";
import pathToUrl from "./PathToUrl";
import { IconContext } from "react-icons";
import { BsFillCartXFill } from "react-icons/bs";
import { fetchWishlistItems } from "../redux/WishlistSlice";
import formatPriceWithCommas from "./PriceFormatter";
import { createPortal } from "react-dom";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { auth, role, token, selectedCurrency } = useSelector(
    (state) => state.user
  );
  const { cartLength, items } = useSelector((state) => state.cart);
  const { wishlistLength } = useSelector((state) => state.wishlist);
  const [totalPrice, setTotalPrice] = useState(0);
  const logoSrc = "/assets/images/logo.png";
  const [searchString, setSearchString] = useState(
    searchParams.get("que") || ""
  );
  const [currencies, setCurrencies] = useState([]);
  const { pathname } = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const onRemove = async (id) => {
    try {
      await toast.promise(dispatch(removeFromCart({ token, id })), {
        pending: "Removing item...",
        success: "Item removed successfully!",
        error: "Failed to remove item.",
      });
    } catch (error) {
      toast.error("Failed to remove item. Please try again.");
    }
  };

  const userProfileLinks = [
    {
      title: "Dashboard",
      url: "/dashboard",
      show: auth && role === "admin",
    },
    {
      title: "Orders",
      url: role === "admin" ? "/orders" : "/my-account/orders",
      show: auth,
    },
    {
      title: "Products",
      url: "/products",
      show: auth && role === "admin",
    },
    {
      title: "Addresses",
      url: "/my-account/addresses",
      show: auth && role === "customer",
    },
    {
      title: "Filters",
      url: "/filters",
      show: auth && role === "admin",
    },
    {
      title: "Discounts",
      url: "/discounts",
      show: auth && role === "admin",
    },
    {
      title: "Currencies",
      url: "/currencies",
      show: auth && role === "admin",
    },
    {
      title: "Users",
      url: "/users",
      show: auth && role === "admin",
    },
    {
      title: "Deleted Users",
      url: "/deleted-users",
      show: auth && role === "admin",
    },
    // {
    //   title: "Account Details",
    //   url:
    //     role === "admin" ? "/account-details" : "/my-account/account-details",
    //   show: auth,
    // },
    {
      title: "Logout",
      url: "/login",
      show: auth,
    },
    {
      title: "Login",
      url: "/login",
      show: !auth,
    },
    {
      title: "Register",
      url: "/register",
      show: !auth,
    },
  ];

  const search = (e) => {
    e.preventDefault();
    navigate({ pathname: "/shop", search: `?que=${searchString}` });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    setTotalPrice(
      items?.reduce(
        (acc, item) =>
          acc +
          item.totalPrice.find((p) => p.currType === selectedCurrency._id)
            ?.amount,
        0
      )
    );
  }, [items, selectedCurrency]);

  useEffect(() => {
    if (auth) {
      dispatch(fetchCartItems(token));
      dispatch(fetchWishlistItems(token));
    }
  }, [auth]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/currencies`)
      .then((response) => {
        setCurrencies(response.data);

        if (
          response.data.length &&
          (!selectedCurrency?._id ||
            !(
              response.data.findIndex(
                (curr) => curr._id === selectedCurrency?._id
              ) > -1
            ))
        )
          dispatch(changeCurrency(response.data[0]));
      })
      .catch(() => {
        toast.error("Failed to fetch currencies");
      });
  }, []);

  const changeSelectedCurrency = (currencyId) => {
    dispatch(changeCurrency(currencies.find((c) => c._id === currencyId)));
  };

  return (
    <header
      id="header"
      className="header style-03 header-dark header-sticky header-transparent"
    >
      <div className="header-wrap-stick">
        <div className="header-position">
          <div className="header-middle">
            <div className="header-middle-inner">
              <div className="header-menu">
                <div className="box-header-nav menu-nocenter">
                  <ul
                    id="menu-primary-menu"
                    className="clone-main-menu kaycee-clone-mobile-menu kaycee-nav main-menu"
                  >
                    <li
                      id="menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-none d-lg-inline-block"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="About"
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li
                      id="menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-none d-lg-inline-block"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Contact"
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                    <li
                      id="menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-none d-lg-inline-block"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Shop"
                        to="/shop"
                      >
                        Shop
                      </Link>
                    </li>
                    {/* <li
                      id="menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Dashboard"
                        to={
                          role === "admin"
                            ? "/dashboard"
                            : "/my-account/dashboard"
                        }
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li
                      id="menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Orders"
                        to={role === "admin" ? "/orders" : "/my-account/orders"}
                      >
                        Orders
                      </Link>
                    </li> */}
                    {/* {role === "admin" ? (
                      <>
                        <li
                          id="menu-item-237"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                        >
                          <Link
                            className="kaycee-menu-item-title"
                            title="Products"
                            to={"/products"}
                          >
                            Products
                          </Link>
                        </li>
                        <li
                          id="menu-item-237"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                        >
                          <Link
                            className="kaycee-menu-item-title"
                            title="Filters"
                            to={"/filters"}
                          >
                            Filters
                          </Link>
                        </li>
                        <li
                          id="menu-item-237"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                        >
                          <Link
                            className="kaycee-menu-item-title"
                            title="Discounts"
                            to={"/discounts"}
                          >
                            Discounts
                          </Link>
                        </li>
                        <li
                          id="menu-item-237"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                        >
                          <Link
                            className="kaycee-menu-item-title"
                            title="Currencies"
                            to={"/currencies"}
                          >
                            Currencies
                          </Link>
                        </li>
                        <li
                          id="menu-item-237"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                        >
                          <Link
                            className="kaycee-menu-item-title"
                            title="Users"
                            to={"/users"}
                          >
                            Users
                          </Link>
                        </li>
                        <li
                          id="menu-item-237"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                        >
                          <Link
                            className="kaycee-menu-item-title"
                            title="Deleted Users"
                            to={"/deleted-users"}
                          >
                            Deleted Users
                          </Link>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                    <li
                      id="menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Logout"
                        to={"#"}
                        onClick={() => {
                          dispatch(logout());
                        }}
                      >
                        Logout
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="block-menu-bar">
                  <a className="menu-bar menu-toggle" href="#">
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </div>
              </div>
              <div className="header-logo">
                <Link to="/">
                  <img alt="Kaycee" src={logoSrc} className="logo" />
                </Link>
              </div>
              <div className="header-control">
                <div className="header-control-inner">
                  <div className="meta-dreaming">
                    <ul className="wpml-menu">
                      <li className="menu-item">
                        <div className="wcml-dropdown product wcml_currency_switcher">
                          {currencies?.length > 1 ? (
                            <ul>
                              <li
                                className="wcml-cs-active-currency"
                                style={{ textTransform: "uppercase" }}
                              >
                                <Link className="wcml-cs-item-toggle">
                                  {selectedCurrency?.code}
                                </Link>
                                <ul className="wcml-cs-submenu">
                                  {currencies?.map((currency) => {
                                    if (currency?._id != selectedCurrency?._id)
                                      return (
                                        <li key={currency._id}>
                                          <Link
                                            onClick={() =>
                                              changeSelectedCurrency(
                                                currency?._id
                                              )
                                            }
                                          >
                                            {currency?.code}
                                          </Link>
                                        </li>
                                      );
                                  })}
                                </ul>
                              </li>
                            </ul>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    </ul>

                    <div className="header-search kaycee-dropdown">
                      <div
                        className="header-search-inner"
                        data-kaycee="kaycee-dropdown"
                      >
                        <Link to="#" className="link-dropdown block-link">
                          <span className="flaticon-magnifying-glass-1"></span>
                        </Link>
                      </div>
                      <div className="block-search">
                        <form
                          role="search"
                          onSubmit={search}
                          className="form-search block-search-form kaycee-live-search-form"
                        >
                          <div className="form-content search-box results-search">
                            <div className="inner">
                              <input
                                autoComplete="off"
                                className="searchfield txt-livesearch input"
                                value={searchString}
                                onChange={(e) =>
                                  setSearchString(e.target.value)
                                }
                                placeholder="Search here..."
                                type="text"
                              />
                            </div>
                          </div>
                          <button type="submit" className="btn-submit">
                            <span className="flaticon-magnifying-glass-1"></span>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="kaycee-dropdown-close">x</div>
                    <div className="menu-item block-user block-dreaming kaycee-dropdown">
                      {/* User Profile Dropdown */}
                      {/* User profile links go here */}
                      <div className="menu-item block-user block-dreaming kaycee-dropdown">
                        <Link className="block-link">
                          <span className="flaticon-profile"></span>
                        </Link>
                        <ul className="sub-menu">
                          {userProfileLinks?.map((link, i) => {
                            if (link.show) {
                              return (
                                <li key={i}>
                                  <Link
                                    to={link.url}
                                    onClick={() => {
                                      if (link.title === "Logout")
                                        dispatch(logout());
                                    }}
                                  >
                                    {link.title}
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>

                    <div className="block-minicart block-dreaming kaycee-dropdown">
                      <div data-kaycee="kaycee-dropdown">
                        <Link
                          className="block-link link-dropdown"
                          to={"/wishlist"}
                        >
                          <span className="flaticon-heart"></span>
                          <span className="count">{wishlistLength || 0}</span>
                        </Link>
                      </div>
                    </div>

                    <div className="block-minicart block-dreaming kaycee-mini-cart kaycee-dropdown">
                      <div
                        className="shopcart-dropdown block-cart-link"
                        data-kaycee="kaycee-dropdown"
                      >
                        <a className="block-link link-dropdown" href="#">
                          <span className="flaticon-shopping-bag-1"></span>
                          <span className="count">{cartLength || 0}</span>
                        </a>
                      </div>

                      <div className="widget kaycee widget_shopping_cart">
                        {(cartLength || 0) > 0 && (
                          <div className="widget_shopping_cart_content">
                            <h3 className="minicart-title">
                              Your Cart
                              <span className="minicart-number-items">
                                {cartLength || 0}
                              </span>
                            </h3>
                            <ul className="kaycee-mini-cart cart_list product_list_widget">
                              {(cartLength || 0) > 0 ? (
                                items?.map((cartItem) => (
                                  <li
                                    key={cartItem?._id}
                                    className="kaycee-mini-cart-item mini_cart_item"
                                  >
                                    <Link
                                      className="remove remove_from_cart_button"
                                      onClick={() => onRemove(cartItem?._id)}
                                    >
                                      <i className="pe-7s-close"></i>
                                    </Link>

                                    <Link to={`/${cartItem?.product?.slug}`}>
                                      <img
                                        src={
                                          cartItem?.product?.imageUrls
                                            ?.length &&
                                          pathToUrl(
                                            cartItem?.product?.imageUrls[0]
                                          )
                                        }
                                        className="attachment-kaycee_thumbnail size-kaycee_thumbnail"
                                        alt={cartItem?.product?.title}
                                        width="600"
                                        height="778"
                                      />
                                      {cartItem?.product?.title} -{" "}
                                      {cartItem.options.reduce(
                                        (acc, option, ind) =>
                                          acc +
                                          option?.selectedOption?.name +
                                          (ind !== cartItem?.options?.length - 1
                                            ? ", "
                                            : ""),
                                        ""
                                      )}
                                    </Link>
                                    {cartItem.totalPrice?.find(
                                      (p) => p.currType === selectedCurrency._id
                                    )?.amount ? (
                                      <span className="quantity">
                                        {cartItem.quantity} ×{" "}
                                        <span className="kaycee-Price-amount amount">
                                          <span className="kaycee-Price-currencySymbol">
                                            {selectedCurrency.symbol}
                                          </span>{" "}
                                          {formatPriceWithCommas(
                                            (
                                              cartItem.totalPrice?.find(
                                                (p) =>
                                                  p.currType ===
                                                  selectedCurrency._id
                                              )?.amount / cartItem.quantity
                                            ).toFixed(2)
                                          )}
                                        </span>
                                      </span>
                                    ) : (
                                      "Not Available"
                                    )}
                                  </li>
                                ))
                              ) : (
                                <></>
                              )}
                            </ul>
                            <p className="kaycee-mini-cart__total total">
                              <strong>Subtotal:</strong>
                              <span className="kaycee-Price-amount amount">
                                <span className="kaycee-Price-currencySymbol">
                                  {totalPrice ? (
                                    selectedCurrency?.symbol
                                  ) : (
                                    <></>
                                  )}
                                </span>{" "}
                                {formatPriceWithCommas(totalPrice.toFixed(2)) ||
                                  "Not Available"}
                              </span>
                            </p>
                            <p className="kaycee-mini-cart__buttons buttons">
                              <Link
                                to="/cart"
                                className="button kaycee-forward"
                              >
                                View cart
                              </Link>
                              <Link
                                to="/checkout"
                                className="button checkout kaycee-forward"
                              >
                                Checkout
                              </Link>
                            </p>
                          </div>
                        )}
                        {(cartLength || 0) <= 0 ? (
                          <div className="widget_shopping_cart_content">
                            <div
                              className="d-flex flex-column justify-content-center align-items-center gap-4"
                              style={{ height: "80vh" }}
                            >
                              <IconContext.Provider
                                value={{ color: "#561f7a" }}
                              >
                                <BsFillCartXFill size={100} />
                              </IconContext.Provider>
                              <p>
                                Nothing in Cart. Please select from the{" "}
                                <Link to="/shop" style={{ color: "#561f7a" }}>
                                  Shop
                                </Link>
                                .
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="header-mobile">
        <div className="header-mobile-left">
          <div className="block-menu-bar">
            <Link className="menu-bar" onClick={() => setShowMobileMenu(true)}>
              <span></span>
              <span></span>
              <span></span>
            </Link>
          </div>
          {createPortal(
            <div
              className={`kaycee-menu-clone-wrap ${showMobileMenu && "open"}`}
            >
              <div className="kaycee-menu-panels-actions-wrap">
                <Link
                  className="kaycee-menu-close-btn "
                  onClick={() => {
                    setShowMobileMenu(false);
                  }}
                >
                  x
                </Link>
              </div>
              <div className="kaycee-menu-panels">
                <div
                  id="kaycee-menu-panel-main"
                  className="kaycee-menu-panel kaycee-menu-panel-main"
                >
                  <ul className="depth-01">
                    <li
                      id="kaycee-menu-clone-menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237  clone-menu-item"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="About"
                        to="/about"
                      >
                        About
                      </Link>
                    </li>
                    <li
                      id="kaycee-menu-clone-menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237  clone-menu-item"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Contact"
                        to="/contact"
                      >
                        Contact
                      </Link>
                    </li>
                    <li
                      id="kaycee-menu-clone-menu-item-237"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237  clone-menu-item"
                    >
                      <Link
                        className="kaycee-menu-item-title"
                        title="Shop"
                        to="/shop"
                      >
                        Shop
                      </Link>
                    </li>
                    {userProfileLinks?.map((link, i) => {
                      if (link.show) {
                        return (
                          <li
                            key={i}
                            id="kaycee-menu-clone-menu-item-237"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-237 d-md-none clone-menu-item"
                          >
                            <Link
                              to={link.url}
                              className="kaycee-menu-item-title"
                              onClick={() => {
                                if (link.title === "Logout") dispatch(logout());
                                setShowMobileMenu(false);
                              }}
                            >
                              {link.title}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>,
            document.body
          )}
          <div className="header-search kaycee-dropdown ">
            <div className="header-search-inner" data-kaycee="kaycee-dropdown">
              <a href="#" className="link-dropdown block-link">
                <span className="flaticon-magnifying-glass-1"></span>
              </a>
            </div>
            <div className="block-search">
              <form
                role="search"
                onSubmit={search}
                className="form-search block-search-form kaycee-live-search-form"
              >
                <div className="form-content search-box results-search">
                  <div className="inner">
                    <input
                      autoComplete="off"
                      className="searchfield txt-livesearch input"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      placeholder="Search here..."
                      type="text"
                    />
                  </div>
                </div>
                <button type="submit" className="btn-submit">
                  <span className="flaticon-magnifying-glass-1"></span>
                </button>
              </form>
            </div>
          </div>
          <ul className="wpml-menu d-none d-md-inline-block">
            <li className="menu-item">
              <div className="wcml-dropdown product wcml_currency_switcher">
                {currencies?.length > 1 ? (
                  <ul className="ml-1">
                    <li
                      className="wcml-cs-active-currency"
                      style={{ textTransform: "uppercase" }}
                    >
                      <Link
                        className="wcml-cs-item-toggle"
                        style={{ fontSize: "18px" }}
                      >
                        {selectedCurrency?.symbol}
                      </Link>
                      <ul className="wcml-cs-submenu">
                        {currencies?.map((currency) => {
                          if (currency?._id != selectedCurrency?._id)
                            return (
                              <li key={currency._id}>
                                <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    changeSelectedCurrency(currency?._id);
                                  }}
                                >
                                  {currency?.symbol}
                                </Link>
                              </li>
                            );
                        })}
                      </ul>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </li>
          </ul>
        </div>
        <div className="header-mobile-mid">
          <div className="header-logo">
            <Link to="/">
              <img alt="Kaycee" src={logoSrc} className="logo" />
            </Link>
          </div>
        </div>
        <div className="header-mobile-right">
          <div className="header-control-inner">
            <div className="meta-dreaming ">
              <div className="menu-item block-user block-dreaming kaycee-dropdown d-none d-md-inline-block">
                <a
                  className="block-link"
                  href="#"
                  style={{ padding: "0", margin: "0" }}
                >
                  <span
                    className="flaticon-profile"
                    style={{ fontSize: "15px" }}
                  ></span>
                </a>
                <ul className="sub-menu">
                  {userProfileLinks?.map((link, i) => {
                    if (link.show) {
                      return (
                        <li
                          key={i}
                          className="menu-item kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--dashboard is-active"
                        >
                          <Link
                            to={link.url}
                            onClick={() => {
                              if (link.title === "Logout") dispatch(logout());
                            }}
                          >
                            {link.title}
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
              <div className="block-minicart block-dreaming d-none d-md-inline-block">
                <div data-kaycee="kaycee-dropdown">
                  <Link
                    className="block-link link-dropdown"
                    to={"/wishlist"}
                    style={{ padding: "0", margin: "0" }}
                  >
                    <span
                      className="flaticon-heart"
                      style={{ fontSize: "15px" }}
                    ></span>
                    <span className="count" style={{ fontSize: "10px" }}>
                      {wishlistLength || 0}
                    </span>
                  </Link>
                </div>
              </div>

              <div className="block-minicart block-dreaming kaycee-mini-cart kaycee-dropdown d-none d-md-inline-block">
                <div
                  className="shopcart-dropdown block-cart-link"
                  data-kaycee="kaycee-dropdown"
                >
                  <a
                    className="block-link link-dropdown"
                    href="#"
                    style={{ padding: "0", margin: "0" }}
                  >
                    <span
                      className="flaticon-shopping-bag-1"
                      style={{ fontSize: "15px" }}
                    ></span>
                    <span className="count">{cartLength || 0}</span>
                  </a>
                </div>
                <div className="widget kaycee widget_shopping_cart">
                  {(cartLength || 0) > 0 ? (
                    <div className="widget_shopping_cart_content">
                      <h3 className="minicart-title">
                        Your Cart
                        <span className="minicart-number-items">
                          {cartLength}
                        </span>
                      </h3>
                      <ul className="kaycee-mini-cart cart_list product_list_widget">
                        {items?.map((cartItem) => (
                          <li
                            key={cartItem._id}
                            className="kaycee-mini-cart-item mini_cart_item"
                          >
                            <Link to="#">
                              <img
                                src={
                                  cartItem?.product?.imageUrls.length > 0 &&
                                  pathToUrl(cartItem?.product?.imageUrls[0])
                                }
                                className="attachment-kaycee_thumbnail size-kaycee_thumbnail"
                                alt={cartItem?.product?.title}
                                width="600"
                                height="778"
                              />
                              {cartItem?.product?.title}&nbsp;
                            </Link>
                            <span className="quantity">
                              {cartItem.quantity} ×{" "}
                              <span className="kaycee-Price-amount amount">
                                <span className="kaycee-Price-currencySymbol">
                                  {selectedCurrency.symbol}
                                </span>
                                {formatPriceWithCommas(
                                  (
                                    cartItem?.totalPrice?.find(
                                      (p) => p.currType === selectedCurrency._id
                                    )?.amount / cartItem.quantity
                                  ).toFixed(2)
                                )}
                              </span>
                            </span>
                          </li>
                        ))}
                      </ul>
                      <p className="kaycee-mini-cart__total total">
                        <strong>Subtotal:</strong>
                        <span className="kaycee-Price-amount amount">
                          <span className="kaycee-Price-currencySymbol">
                            {selectedCurrency?.symbol}
                          </span>{" "}
                          {formatPriceWithCommas(totalPrice.toFixed(2))}
                        </span>
                      </p>
                      <p className="kaycee-mini-cart__buttons buttons">
                        <Link to="/cart" className="button kaycee-forward">
                          Viewcart
                        </Link>
                        <Link
                          to="/checkout"
                          className="button checkout kaycee-forward"
                        >
                          Checkout
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <></>
                  )}
                  {(cartLength || 0) <= 0 ? (
                    <div className="widget_shopping_cart_content">
                      <div
                        className="d-flex flex-column justify-content-center align-items-center gap-4"
                        style={{ height: "80vh" }}
                      >
                        <IconContext.Provider value={{ color: "#561f7a" }}>
                          <BsFillCartXFill size={100} />
                        </IconContext.Provider>
                        <p>
                          Nothing in Cart. Please select from the{" "}
                          <Link to="/shop" style={{ color: "#561f7a" }}>
                            Shop
                          </Link>
                          .
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <ul className="wpml-menu d-md-none p-0">
                <li className="menu-item">
                  <div className="wcml-dropdown product wcml_currency_switcher">
                    {currencies?.length ? (
                      <ul>
                        <li
                          className="wcml-cs-active-currency"
                          style={{ textTransform: "uppercase" }}
                        >
                          <Link
                            className="wcml-cs-item-toggle"
                            style={{ fontSize: "18px" }}
                          >
                            {selectedCurrency?.symbol}
                          </Link>
                          <ul
                            className="wcml-cs-submenu"
                            style={{ right: 0, left: "auto" }}
                          >
                            {currencies?.map((currency) => {
                              if (currency?._id != selectedCurrency?._id)
                                return (
                                  <li key={currency._id}>
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        changeSelectedCurrency(currency?._id);
                                      }}
                                    >
                                      {currency?.symbol}
                                    </Link>
                                  </li>
                                );
                            })}
                          </ul>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
