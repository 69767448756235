import React from "react";

const SortModal = ({ showModal, closeModal, setSortBy }) => {
  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"} d-lg-none`}
        id="sortModal"
      >
        {/* <ToastContainer hideProgressBar={true} autoClose={2000} /> */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header my-2">
              {/* <div className="d-flex justify-content-center align-items-center gap-2">
                <><h5 className="modal-title">{userName}</h5></>
              </div> */}
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            <table style={{ marginTop: "15px" }}>
              <tbody>
                <tr>
                  <td
                    onClick={() => {
                      closeModal();
                      setSortBy("");
                    }}
                  >
                    Relevance
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => {
                      closeModal();
                      setSortBy("title");
                    }}
                  >
                    Title (A - Z)
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => {
                      closeModal();
                      setSortBy("-title");
                    }}
                  >
                    Title (Z - A)
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => {
                      closeModal();
                      setSortBy("basePrice");
                    }}
                  >
                    Price (Low - High)
                  </td>
                </tr>
                <tr>
                  <td
                    onClick={() => {
                      closeModal();
                      setSortBy("-basePrice");
                    }}
                  >
                    Price (High - Low)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SortModal;
