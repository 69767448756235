"use client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logout } from "./UserSlice";
import { clearWishlist } from "./WishlistSlice";

export const fetchCartItems = createAsyncThunk(
  "fetchCartItems",
  async (token, thunkAPI) => {
    if (!token) return [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/cart`,
        {
          headers: { Authorization: token },
        }
      );
      return response.data.items;
    } catch (error) {
      if (error.response.status === 401) {
        thunkAPI.dispatch(logout());
        thunkAPI.dispatch(clearWishlist());
        thunkAPI.dispatch(clearCart());
      }
      throw error.response.data;
    }
  }
);

export const addToCart = createAsyncThunk(
  "addToCart",
  async ({ token, itemDetail }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart`,
        itemDetail,
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );
      return response.data.items;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const updateCartItem = createAsyncThunk(
  "updateCartItem",
  async ({ token, id, updatedData }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/cart/${id}`,
        updatedData,
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );
      return response.data.items;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const removeFromCart = createAsyncThunk(
  "removeFromCart",
  async ({ token, id }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/cart/${id}`,
        {
          headers: { Authorization: token },
        }
      );
      return response.data.items;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const CartSlice = createSlice({
  name: "cart",
  initialState: { items: [], status: "idle", error: null, cartLength: 0 },
  reducers: {
    clearCart: (state, action) => {
      state.items = [];
      state.status = "idle";
      state.error = null;
      state.cartLength = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.cartLength = action?.payload?.length;
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCartItem.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.cartLength = action?.payload?.length;
      })
      .addCase(updateCartItem.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(removeFromCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.cartLength = action?.payload?.length;
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchCartItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload;
        state.cartLength = action?.payload?.length;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default CartSlice.reducer;

export const { clearCart } = CartSlice.actions;
