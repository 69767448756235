import { useEffect } from "react";
import { useState } from "react";
import UserDetailsTabs from "./UserDetailTabs";
import { ToastContainer } from "react-toastify";

const UserDetailModal = ({
  showModal,
  closeModal,
  user,
  userWishlist,
  userCart,
  fetchPage,
  fetchUser,
}) => {
  const [userName, setUserName] = useState(user?.title);

  useEffect(() => {
    setUserName(user?.fullName);
  }, [user]);

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : "hide"}`}
        id="orderDetailModal"
      >
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <>
                  <h5 className="modal-title">{userName}</h5>
                </>
              </div>
              <button type="button" className="close" onClick={closeModal}>
                &times;
              </button>
            </div>
            {user && (
              <UserDetailsTabs
                user={user}
                cartItems={userCart}
                wishlistItems={userWishlist}
                fetchPage={fetchPage}
                fetchUser={fetchUser}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailModal;
