import React, { useEffect, useState } from "react";
import MyAccountLayout from "../../layouts/MyAccount";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import pathToUrl from "../../components/PathToUrl";
import formatPriceWithCommas from "../../components/PriceFormatter";

const Order = () => {
  const { token } = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [orderLoadingStatus, setOrderLoadingStatus] = useState(false);

  const [order, setOrder] = useState({
    items: [],
    shippingAddress: "",
  });

  const openModal = (orderDetails) => {
    setOrder(orderDetails);
    setShowModal(true);
  };

  const closeModal = () => {
    setOrder(null);
    setShowModal(false);
  };

  const fetchOrder = (orderId) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/order/${orderId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        openModal(res.data && res.data[0]);
      })
      .catch((err) => toast.error("Failed to fetch order"));
  };

  useEffect(() => {
    setOrderLoadingStatus(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/order?status=${status}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setOrderLoadingStatus(false);
        setOrders(res.data);
      })
      .catch((err) => {
        toast.error("Failed to fetch orders.");
        setOrderLoadingStatus(false);
      });
  }, [status]);

  const updateStatus = (id, status) => {
    let config = {
      method: "patch",
      url: `${process.env.REACT_APP_BACKEND_URL}/order/${id}`,
      headers: {
        Authorization: token,
      },
      data: { status },
    };

    axios
      .request(config)
      .then((res) => {
        const index = orders.findIndex((order) => order?._id === id);

        if (index !== -1) {
          const updatedOrders = [...orders];
          updatedOrders[index] = res.data;

          setOrders(updatedOrders);
          toast.success("Order status updated successfully!");
        }
      })
      .catch((error) => {
        toast.error("Failed to update order status. Please try again.");
      });
  };

  const deleteOrder = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/order/${id}`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res?.status === 200) {
          setOrders((currOrder) =>
            currOrder?.filter((order) => order?._id !== id)
          );
          toast.success("Order deleted successfully!");
        }
      })
      .catch((error) => {
        toast.error("Failed to delete order. Please try again.");
      });
  };

  return (
    <>
      <OrderDetailModal
        showModal={showModal}
        closeModal={closeModal}
        order={order}
        updateStatus={updateStatus}
        deleteOrder={deleteOrder}
        orderLoadingStatus={orderLoadingStatus}
      />
      <MyAccountLayout>
        <div className="kaycee-MyAccount-content">
          <div className="d-flex align-items-center justify-content-end mb-4">
            <div
              id="kaycee_product_search-2"
              className="widget kaycee widget_product_search"
              onSubmit={(e) => {
                e.preventDefault();
                // fetchPage();
              }}
            >
              <form className="kaycee-ordering" method="get">
                <select
                  title="product_cat"
                  name="orderby"
                  className="orderby"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="pending">Pending </option>
                  <option value="processing">Processing </option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="rejected">Rejected</option>
                </select>
              </form>
            </div>
          </div>
          {orderLoadingStatus && (
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-4"
              style={{ height: "40vh" }}
            >
              <img src="/assets/images/Rolling-1s-200px.svg" />
            </div>
          )}
          {!orderLoadingStatus &&
            (!orders.length ? (
              <div className="kaycee-Message kaycee-Message--info kaycee-info text-center">
                No orders available yet.
              </div>
            ) : (
              <table
                className="kaycee-orders-table kaycee-MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table"
                style={{ overflowX: "auto" }}
              >
                <thead>
                  <tr>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-number">
                      <span className="nobr">Order</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-date">
                      <span className="nobr">Date</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-status">
                      <span className="nobr">Status</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-total">
                      <span className="nobr">Total</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-paymentMethod">
                      <span className="nobr">Payment Method</span>
                    </th>
                    <th className="kaycee-orders-table__header kaycee-orders-table__header-order-actions">
                      <span className="nobr">Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((order, index) => {
                    if (status !== "all" && order?.status !== status)
                      return <></>;

                    const orderDate = new Date(order?.orderDate);
                    const formattedDate = dateFormatter(orderDate);
                    return (
                      <tr
                        key={order?._id}
                        className="kaycee-orders-table__row kaycee-orders-table__row--status-on-hold order"
                      >
                        <td
                          className="kaycee-orders-table__cell kaycee-orders-table__cell-order-number"
                          data-title="Order"
                        >
                          {index + 1}
                        </td>
                        <td
                          className="kaycee-orders-table__cell kaycee-orders-table__cell-order-date"
                          data-title="Date"
                        >
                          <time dateTime={formattedDate}>{formattedDate}</time>
                        </td>
                        <td
                          className="kaycee-orders-table__cell kaycee-orders-table__cell-order-status"
                          data-title="Status"
                          style={{ textTransform: "capitalize" }}
                        >
                          {order?.status}
                        </td>
                        <td
                          className="kaycee-orders-table__cell kaycee-orders-table__cell-order-total"
                          data-title="Total"
                        >
                          <span className="kaycee-Price-amount amount">
                            <span className="kaycee-Price-currencySymbol">
                              {order?.currency?.symbol}{" "}
                            </span>
                            {formatPriceWithCommas(
                              order?.totalPrice.toFixed(2) || 0
                            )}
                          </span>
                        </td>
                        <td className="kaycee-orders-table__cell">
                          {order?.paymentMethod}
                        </td>
                        <td
                          className="kaycee-orders-table__cell kaycee-orders-table__cell-order-actions"
                          data-title="Actions"
                        >
                          <button
                            className="kaycee-button button btn themeBtn"
                            onClick={() => {
                              fetchOrder(order?._id);
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ))}
        </div>
      </MyAccountLayout>
    </>
  );
};

const OrderDetailModal = ({
  showModal,
  closeModal,
  order,
  updateStatus,
  deleteOrder,
  orderLoadingStatus,
}) => {
  const { role } = useSelector((state) => state.user);
  const [status, setStatus] = useState(order?.status);
  return (
    <div
      className={`modal ${showModal ? "show" : "hide"}`}
      id="orderDetailModal"
    >
      <ToastContainer hideProgressBar={true} autoClose={2000} />
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">Order Details</h5>
            <button type="button" className="close" onClick={closeModal}>
              &times;
            </button>
          </div>
          {order && (
            <div className="modal-body" style={{ overflow: "auto" }}>
              <div className="d-flex justify-content-between">
                <div>
                  <address style={{ margin: 0 }}>
                    <h6 style={{ fontWeight: "bold", margin: 0 }}>
                      Shipping Address:
                    </h6>
                    <p>
                      <strong>{order?.shippingAddress?.fullName}</strong>
                    </p>
                    <p style={{ margin: 0 }}>
                      {order?.shippingAddress?.line1}
                      {order?.shippingAddress?.line2 &&
                        `, ${order?.shippingAddress?.line2}`}
                      <br />
                      {order?.shippingAddress?.city},{" "}
                      {order?.shippingAddress?.state} <br />
                      {order?.shippingAddress?.postalCode},{" "}
                      {order?.shippingAddress?.country}
                    </p>
                  </address>
                  <span>
                    <h6
                      style={{ fontWeight: "bold" }}
                      className="d-inline-block"
                    >
                      Payment Method:
                    </h6>{" "}
                    <p className="d-inline-block">{order?.paymentMethod}</p>
                  </span>
                </div>
                {role === "admin" ? (
                  <select
                    className="nice-select"
                    name="status"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="processing">Processing </option>
                    <option value="shipped">Shipped</option>
                    <option value="delivered">Delivered</option>
                    <option value="rejected">Rejected</option>
                  </select>
                ) : (
                  <span
                    style={{
                      padding: "4px 20px",
                      textTransform: "capitalize",
                      height: "35px",
                      borderRadius: "20px",
                      color: "white",
                    }}
                    className={order?.status}
                  >
                    {order?.status}
                  </span>
                )}
              </div>
              <div className="cart-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="pro-thumbnail">Thumbnail</th>
                      <th className="pro-price">Price</th>
                      <th className="pro-title">Product</th>
                      <th className="pro-quantity">Quantity</th>
                      <th className="pro-subtotal">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.items?.map((item, index) => {
                      return (
                        <tr key={item?._id || index}>
                          <td className="pro-thumbnail">
                            <a href="#">
                              <img
                                className="img-fluid"
                                style={{ maxHeight: "100px" }}
                                src={
                                  item?.product?.imageUrls &&
                                  pathToUrl(item?.product?.imageUrls[0])
                                }
                                alt={item?.product?.title}
                              />
                            </a>
                          </td>
                          <td className="pro-title">
                            <Link to={`/${item?.product?.slug}`}>
                              {item?.product?.title} -{" "}
                              {item?.options?.reduce(
                                (acc, option, ind) =>
                                  acc +
                                  option.selectedOption.name +
                                  (ind !== item?.options?.length - 1
                                    ? ", "
                                    : ""),
                                ""
                              )}
                            </Link>
                          </td>
                          <td className="pro-price">
                            <span>
                              {order?.currency?.symbol}{" "}
                              {item?.totalPrice
                                ?.find(
                                  (p) => p.currType === order.currency.currType
                                )
                                ?.amount?.toFixed(2) / item?.quantity}
                            </span>
                          </td>
                          <td className="pro-quantity">
                            <div className="pro-qty">
                              <input
                                type="text"
                                value={item?.quantity}
                                readOnly={true}
                                style={{ maxWidth: "100px" }}
                              />
                            </div>
                          </td>
                          <td className="pro-subtotal">
                            <span>
                              {order?.currency?.symbol}{" "}
                              {item?.totalPrice
                                ?.find(
                                  (p) => p.currType === order.currency.currType
                                )
                                ?.amount?.toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            className="modal-footer flex-column flex-md-row"
            style={{ textTransform: "capitalize" }}
          >
            <div
              style={{ marginRight: "auto" }}
              className="d-flex align-items-center flex-wrap"
            >
              <span className="mr-3">
                <h6 style={{ fontWeight: "600" }}>Sub-Total Amount:</h6>{" "}
                {order?.currency?.symbol}
                {formatPriceWithCommas(order?.subTotalPrice?.toFixed(2))}
              </span>
              <span className="mr-3">
                <h6 style={{ fontWeight: "600" }}>Discount Amount:</h6>{" "}
                {order?.currency?.symbol}
                {formatPriceWithCommas(order?.totalDiscount?.toFixed(2))}
              </span>
              <span>
                <h6 style={{ fontWeight: "600" }}>Total Amount:</h6>{" "}
                {order?.currency?.symbol}
                {formatPriceWithCommas(order?.totalPrice?.toFixed(2))}
              </span>
            </div>
            {order?.status === "pending" && role !== "admin" && (
              <button
                className="btn themeBtn mt-4 mb-2 mr-auto ml-md-auto"
                onClick={() => {
                  updateStatus(order?._id, "cancelled");
                  closeModal();
                }}
              >
                Cancel Order
              </button>
            )}
            {order?.status !== "cancelled" && role === "admin" && (
              <button
                className="btn themeBtn mt-4 mb-2 mr-auto ml-md-auto"
                onClick={() => {
                  updateStatus(order?._id, status);
                  closeModal();
                }}
                disabled={order?.status === status && true}
              >
                Update Order
              </button>
            )}
            {role === "admin" && (
              <button
                className="btn themeBtn mt-4 mb-2 mr-auto ml-md-auto"
                onClick={() => {
                  deleteOrder(order?._id);
                  closeModal();
                }}
              >
                Delete Order
              </button>
            )}
            {/* {order?.status === "delivered" && (
                <button className="btn themeBtn">Download Invoice</button>
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

const dateFormatter = (inputDate) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(inputDate);
};

export default Order;
