import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../redux/UserSlice";

const MyAccountNavigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { auth, role } = useSelector((state) => state.user);
  const pathnames = location.pathname;
  const links = [
    {
      title: "Dashboard",
      className:
        "kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--dashboard ",
      href: "/dashboard",
      show: auth && role === "admin",
    },
    {
      title: "Orders",
      className:
        "kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--orders",
      href: role === "admin" ? "/orders" : "/my-account/orders",
      show: auth,
    },
    {
      title: "Products",
      className:
        "kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--downloads",
      href: "/products",
      show: auth && role === "admin",
    },
    {
      title: "Addresses",
      className:
        "kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--edit-address",
      href: "/my-account/addresses",
      show: auth && role === "customer",
    },
    {
      title: "Filters",
      href: "/filters",
      show: auth && role === "admin",
    },
    {
      title: "Discounts",
      href: "/discounts",
      show: auth && role === "admin",
    },
    {
      title: "Currencies",
      href: "/currencies",
      show: auth && role === "admin",
    },
    {
      title: "Users",
      href: "/users",
      show: auth && role === "admin",
    },
    {
      title: "Deleted Users",
      href: "/deleted-users",
      show: auth && role === "admin",
    },
    // {
    //   title: "Account details",
    //   className:
    //     "kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--edit-account",
    //   href:
    //     role === "admin" ? "/account-details" : "/my-account/account-details",
    //   show: auth,
    // },
    {
      title: "Logout",
      className:
        "kaycee-MyAccount-navigation-link kaycee-MyAccount-navigation-link--customer-logout",
      href: "/login",
      show: auth,
    },
  ];

  return (
    <nav className="kaycee-MyAccount-navigation d-none d-lg-block">
      <ul>
        {links.map((link, i) => {
          if (link.show) {
            return (
              <li
                key={i}
                className={
                  link.className + pathnames === link.href ? " is-active" : ""
                }
              >
                <Link
                  to={link.href}
                  onClick={() => {
                    if (link.title === "Logout") dispatch(logout());
                  }}
                >
                  {link.title}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </nav>
  );
};

export default MyAccountNavigation;
