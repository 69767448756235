import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToWishlist } from "../../redux/WishlistSlice";
import { Link } from "react-router-dom";
import pathToUrl from "../PathToUrl";
import { filterProduct } from "../../redux/ProductSlice";
import formatPriceWithCommas from "../PriceFormatter";

const TrendItem = ({
  productData,
  _id,
  slug,
  imageUrls,
  title,
  basePrice,
  discount,
  isNew,
  tags,
}) => {
  const dispatch = useDispatch();
  const { token, selectedCurrency } = useSelector((state) => state.user);
  const primaryImage = imageUrls && `${imageUrls[0]}`;

  const addItem = async () => {
    if (!token) return toast.error("Login to add item in wishlist");
    try {
      await toast.promise(dispatch(addToWishlist({ token, id: _id })), {
        pending: "Adding to Wishlist...",
        success: "Item added to Wishlist!",
        error: "Failed to add item to Wishlist.",
      });
    } catch (error) {
      toast.error("Failed to add item to Wishlist");
    }
  };
  return (
    <div className="product-item best-selling style-04 rows-space-30 col-bg-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-ts-6 post-25 product type-product status-publish has-post-thumbnail product_cat-light product_cat-chair product_cat-specials product_tag-light product_tag-sock first instock sale featured shipping-taxable purchasable product-type-simple">
      <div className="product-inner tooltip-top tooltip-all-top product_Item">
        <div className="product-thumb">
          <Link
            className="thumb-link d-flex justify-content-center align-items-center"
            to={`/${slug}`}
            style={{ height: "150px" }}
          >
            <img
              className="img-responsive"
              src={pathToUrl(primaryImage)}
              alt={title}
              style={{ height: "100%" }}
            />
          </Link>
          <div className="flash">
            {discount && (
              <span className="onsale">
                <span className="number">-{discount}%</span>
              </span>
            )}
            {tags.filter((tag) => tag.toLowerCase() === "new")?.length ? (
              <span className="onnew" style={{ display: "block" }}>
                <span className="text">New</span>
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="group-button">
            <Link to={`/${slug}`} className="button yith-wcqv-button">
              Quick View
            </Link>
            <div className="yith-wcwl-add-to-wishlist">
              <div className="yith-wcwl-add-button show">
                <Link onClick={addItem} className="add_to_wishlist">
                  Add to Wishlist
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="product-info">
          <h3 className="product-name product_title">
            <Link to={`/${slug}`}>{title}</Link>
          </h3>
          <span className="price">
            {/* {regularPrice && (
              <del>
                <span className="kaycee-Price-amount amount">
                  <span className="kaycee-Price-currencySymbol">$</span>
                  {regularPrice}
                </span>
              </del>
            )} */}
            {basePrice.find((p) => p?.currType?._id === selectedCurrency?._id)
              ?.amount ? (
              <ins>
                <span className="kaycee-Price-amount amount">
                  <span className="kaycee-Price-currencySymbol">
                    {selectedCurrency.symbol}{" "}
                  </span>
                  {formatPriceWithCommas(
                    basePrice
                      .find((p) => p?.currType?._id === selectedCurrency?._id)
                      ?.amount?.toFixed(2)
                  )}
                </span>
              </ins>
            ) : (
              <></>
            )}
          </span>
          {/* <div className="rating-wapper nostar">
            <div className="star-rating">
              <span style={{ width: `${(rating / 5) * 100}%` }}>
                Rated <strong className="rating">{rating}</strong> out of 5
              </span>
            </div>
            <span className="review">({reviews})</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
const TrendSection = () => {
  const { data } = useSelector((state) => state.product);
  const { selectedCurrency } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/product/filter?limit=12&page=1`;

    dispatch(
      filterProduct({
        url,
        body: {
          sortBy: "-createdAt",
          selectedCurr: selectedCurrency._id,
        },
      })
    );
  }, []);
  return (
    <div className="section-001 pb-0">
      <div className="container">
        <div className="kaycee-heading style-01">
          <div className="heading-inner">
            <h3 className="title">
              Top Trend <span></span>
            </h3>
            <div className="subtitle">
              Browse our website for the hottest items now.
            </div>
          </div>
        </div>
        <div className="kaycee-products style-04">
          <div
            className="response-product product-list-grid row auto-clear equal-container better-height"
            style={{
              // gap: "1px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            {data.map((item) => (
              <TrendItem key={item?._id} {...item} />
            ))}
          </div>
          <div className="shop-all">
            <Link to={"/shop"}>Discovery All</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendSection;
