import React, { Children } from "react";
import Breadcrumbs from "../components/Breadcrumb";
import MyAccountNavigation from "../components/MyAccountNavigation";

const MyAccountLayout = ({ children }) => {
  return (
    <>
      <Breadcrumbs />
      <main className="site-main main-container no-sidebar">
        <div className="container">
          <div className="row">
            <div className="main-content col-md-12">
              <div className="page-main-content">
                <div className="kaycee">
                  <MyAccountNavigation />
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MyAccountLayout;
